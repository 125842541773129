@import 'theme/variables', 'theme/colors';

.dashboard-card {
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  .card__header {
    padding: 16px;
    border-bottom: 1px solid $color-gray-soft;
    h3 {
      font-weight: $semibold;
    }
  }
  .card__body {
    padding: 20px 16px;
    text-align: center;
    min-height: 170px;
    h3 {
      color: var(--color-secondary);
      font-weight: $semibold;
      max-width: 336px;
      margin: auto;
    }
    p {
      font-size: $font-size-tag;
      max-width: 420px;
      margin: auto;
      margin-top: 18px;
    }
    h3.empty {
      max-width: 300px;
      color: $color-gray;
      text-align: center;
      margin: 35px auto $spacing;

      & + button {
        margin-bottom: 15px;
      }
    }
  }
  .card__footer {
    margin: 10px;
    display: flex;
    justify-content: center;
    .MuiMobileStepper-root {
      display: flex;
      justify-content: center;
      background-color: transparent;
    }
    .MuiMobileStepper-dot {
      cursor: pointer;
    }
    .custom-dot {
      width: 12px;
      height: 12px;
      background-color: $color-gray-soft;
      border-radius: 50%;
      margin: 8px;
      &.selected {
        background-color: $color-gray;
      }
    }
  }
}
