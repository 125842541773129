@import 'theme/variables', 'theme/colors';

.close-objectives-modal{
	.MuiDialog-container{
		.MuiPaper-root{
			align-self: flex-end;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			margin: 0;
			min-height: 250px;
			width: 100%;
			.cancel-modal-content {
				align-items: center;
				height: 250px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				.button {
					width: 80%;
				}
				.icon-container {
					align-items: center;
					border-top: 3px solid rgba($color: #000000, $alpha: 0.12);
					display: flex;
					justify-content: center;
					padding-top: 24px;
					.icon {
						color: $color-warning;
						height: 50px;
						width: 50px;
					}
				}
			}
		}
	}
}