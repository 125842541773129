
.automatic-timeframes-form-component {
  display: flex;
  width: 100%;
  margin-top: 23px;
  .timeframe, .start-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 14px;
      margin-right: 13px;
    }
  }

  .timeframe {
    margin-right: 40px;
    width: 192px;
  }

  .start-date {
    margin-right: 24px;
  }
}