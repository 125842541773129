@import '~theme/colors';

.tegdu-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    overflow: hidden;
    margin-bottom: 24px;
    .with-close {
      display: flex;
      align-items: center;
      width: fit-content;
      .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
      }
    }

    .with-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-black-text;
      margin-left: 20px;
    }
  }
}