@import 'theme/variables';

.container-settings-okrs {
  .label {
    margin: 25px 0;
    font-size: $font-size-label;
    font-weight: $medium;
  }
  .group {
    display: flex;
    align-items: center;
    .description {
      margin-right: 70px;
    }
  }
}
