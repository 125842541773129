@import '~theme/variables', '~theme/colors';
[data-rsbs-overlay], [data-rsbs-backdrop]{
  z-index: 99999 !important;
}
.objectives-bottom-sheet-container{
  height: 324px;
  display: flex;
  margin: 10px 16px 0px 16px;
  flex-direction: column;
  .header-sheet{
    display: flex;
    flex: 1;
    div:nth-child(1){
      flex: 1;
      span{
        font-size: 18px;
        font-weight: $semibold !important;
        color: $color-black-text;
      }
    }
    div:nth-child(2){
      flex: 1;
      display: flex;
      justify-content: flex-end;
      span{
        font-size: 18px;
        font-weight: $medium !important;
        color: $color-black-text;
        text-decoration: underline;
      }
    }
  }
  .content-sheet{
    flex: 8;
    .role-content{
      .title-content{
        margin-bottom: 12px;
        span{
          font-size: 16px;
          font-weight: $medium !important;

        }
      }
      .buttons-content-filters{
        .container-buttons-filters{
          height: 40px;
          display: flex;
          margin-bottom: 30px;
          justify-content: space-between;
          align-items: center;
          .button-filter-selected{
            padding: 10px !important;
            height: 32px;
            color: $color-white;
            background-color: $color-gray-dark;
            border-radius: 16px !important;
            font-weight: normal !important;
            font-size: 14px;
            font-family: $font-family-primary !important;
            .MuiButton-label{
              font-weight: normal !important;
            }
          }
          .button-filter{
            padding: 10px !important;
            height: 32px;
            color: $color-black-text;
            background-color: $color-gray-f1;
            border-radius: 16px !important;
            font-size: 14px;
            font-family: $font-family-primary !important;
            .MuiButton-label{
              font-weight: normal !important;
            }
          }
          .container-icon-filter{
            margin-left: 5px;
            .MuiSvgIcon-root{
              color:$color-gray-ae ;
            }
          }
        }
      }
    }
  }
}