@import 'theme/variables', 'theme/colors';

.statement-container {
  .organization-footer {
    border-top: 1px solid $color-gray-soft;
    padding-top: 24px;
    margin-top: 12px;
    color: $color-gray;
    span {
      font-size: $font-size-h4;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
