@import "theme/variables", "theme/colors";

.container-log{
  display: flex;
  align-items: center;
  width: 100%;
  .content-log{
    display: flex;
    align-items: center;
    margin-left: 12px;
    flex: 9;
    span{
      color:$color-gray;
      font-size: 14px;
      b{
        color: $color-gray-dark;
      }
    }
  }
  .date-log{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color:$color-gray;
    font-size: 14px;
  }
}