@import '~theme/variables', "~theme/colors";

.component-mobile-form-individual-rate{
  padding: 30px 20px 0px;

  .component-item-individualrate {
    margin-bottom: $spacing;
  }

  .container-btn-tap-bottom.individual-review {
    display: flex;
    gap: 16px;
    margin-top: 48px;
    left: 0;
    padding: 0 20px;
  }
  & .individual-review-info-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;

    & .objective-icon-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;      
      width: 100%;
      margin: 10px 0 50px;
      font-weight: $semibold;
      .MuiSvgIcon-root {
        color: $color-gray-icons-color;
      }
      & span {
        margin-left: 19px;
        color: $color-black-text;
      }
    }
  }
}