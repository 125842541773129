@import '~theme/variables', '~theme/colors';

.tegdu-dropable-area {
  width: 100%;
  height: 104px;
  border: 1px solid $color-gray;
  border-style: dashed;
  .dropable-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: $color-black-text;
      opacity: 0.54;
      .icon {
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
    }
    #select-file {
      text-decoration: underline;
      cursor: pointer;
      margin-top: 10px;
      font-size: 14px;
      color: $color-black-text;
      opacity: 0.54;
    }
    .dropable-area-content {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      flex-direction: row;
      padding: 24px;
      .filename {
        display: flex;
        align-items: center;
        width: 50%;
        font-size: 14px;
        span {
          margin-left: 18px;
        }
      }
      .remove {
        display: flex;
        align-items: center;
        width: 50%;
        justify-content: flex-end;
        color: $color-secondary-red;
        text-decoration: underline;
        font-size: 14px;
        span {
          cursor: pointer;
        }
      }
    }
  }
}