@import 'theme/variables', 'theme/colors';

.generic-data-table {
  .generic-sort-icon {
    margin-left: 8px !important;
    .MuiSvgIcon-root {
      color: $color-gray-dark;
    }
  }

  .table-error-message {
    padding: $spacing;
    text-align: center;

    p {
      margin-bottom: 20px;
    }
  }

  .rdt_TableHeader {
    display: none;
  }

  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        color: $color-gray-dark;
        font-weight: $semibold;
        font-size: 13px;
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      border-bottom: none !important;
      color: $color-black-text;
      font-size: $font-size-p;

      .rdt_TableCell {
        font-weight: $semibold;
      }
    }
    .rdt_TableRow:hover {
      background-color: #f5f5f5 !important; // TODO: ADD COLOR TO THEME
    }
  }

  .rdt_Pagination {
    color: $color-black-text;
    font-size: $font-size-p;
  }
}
