@import 'theme/variables', 'theme/colors';

.expiredinvitationcode-container {
  display: flex;
  flex-direction: column;
  margin: auto;

  .expiredinvitationcode-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 464px;
    background-color: $color-white;  
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 32px;
    
    img {
      height: 39px;
      width: 89px;
      margin-bottom: 32px;
    }

    .expiredinvitationcode-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      .warning-element {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;

        .MuiSvgIcon-root {
          height: 37px;
          width: 37px;
          color: $color-error;
        }

        span {
          font-size: 20px;
          margin-left: 21px;        
        }
      }
    }
  }

  .expiredinvitationcode-redirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: $spacing;
    span {
      font-size: 14px;
      font-weight: $semibold;
    }
    .expiredinvitationcode-redirect-link {
      color: $color-primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}