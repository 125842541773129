@import 'theme/variables';

.basic-objective-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 24px;

  .owner-coach {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    width: 100%;
    gap: 24px;

    .owner, .coach {
      width: 100% !important;
    }
  }

  .instructions-container {
    .instructions-title {
      font-size: 20px;
      font-weight: $semibold;
    }

    .instructions-description {
      font-size: 14px;
    }
  }
}