@import 'theme/variables', 'theme/colors', 'theme/fontface';

.search-objective-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .search-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    span {
      font-size: $font-size-p;
      margin-left: 15px;
    }
  }

  .info-area {
    display: flex;
    width: 100%;
    height: 100%;
    .info-loading {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
    .info-error {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .info-success {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 6px;
      
      .info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: $font-size-p;
        margin-bottom: 24px;
        .okr-name {
          width: 165px;
        }
        .okr-date {
          width: 110px;
        }
        .okr-progress {
          display: flex;
          align-items: center;
          .container-circularprogress {
            flex-direction: row;
          }
        }
      }
    }
  }
}