@import "theme/variables", "theme/colors";

.accordion-container{
  margin-top: $spacing;
  .column-container-selected {
    align-items: center;
    background-color: $color-primaryLight;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 48px;
    opacity: 1;
    max-width: 640px;
    .column-text{
      margin-left: 16px;
    }
    .expendable-button{
      align-items: center;
      background-color: $color-primaryLight;
      border: 0;
      height: 24px;
      justify-content: center;
      margin-right: 16px;
      width: 24px;
      .icon{
        color: $color-black-text;
        opacity: 1;
      }
    }
  }
  .column-container{
    align-items: center;
    background-color: $color-gray-soft-light;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 48px;
    opacity: 1;
    max-width: 640px;
    .column-text{
      margin-left: 16px;
    }
    .expendable-button{
      align-items: center;
      background-color: $color-gray-soft-light;
      border: 0;
      height: 24px;
      justify-content: center;
      margin-right: 16px;
      width: 24px;
      .icon{
        color: $color-black-text;
        opacity: 1;
      }
    }
  }
  .text-container{
    margin-top: 16px;
    max-width: 640px;
    .text{
      color: $color-black-text;
    }
  }
}

@media only screen and (max-width: 428px){
  .accordion-container{
    margin-top: $spacing-2x;
  }
}

