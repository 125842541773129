@import '~theme/variables', '~theme/colors';

.container-info-bottom-descr{
  .header{
    display: flex;
    padding: 16px 16px 0 16px;
    gap: 15px;
    .progress-circular{
      display: flex;
      align-items: center;
    }
    .title-kr{
      display: flex;
      align-items: center;
      span{
        font-size: 18px;
        font-weight: $medium;
      }
    }
  }
  .container-description{
    padding: 24px 16px 58px 16px;
    span{
      font-size: 16px;
      color: $color-gray;
    }
  }
}