@import "theme/variables", "theme/colors";

 form{
  & .section {
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    & .dropdowns-wrapper{
      display: flex;
      column-gap: 40px;
    }
  }
  & .spacing{
    row-gap: 18px;
    margin-bottom: 24px;
  }
  & .none{
    display: none;
  }
  & .block{
    display: block;
  }
  & .label-switch-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    & label {
      font-weight: $regular;
      font-size: $font-size-input;
    }
  }
  & .objective-date-dropdown {
    display: flex;
    align-items: center;

    & label {
      font-weight: $regular;
      font-size: $font-size-input;
      margin-right: 14px;
    }
    & .objective-reminder-dropdown{
      width: 186px;
    }
  }
  & .objective-date-dropdown {
    display: flex;
    align-items: center;

    & label {
      font-weight: $regular;
      font-size: $font-size-input;
      margin-right: 14px;
    }
    & .objective-reminder-dropdown{
      width: 186px;
    }
  }
  & .objective-date-dropdown.first{
    margin-bottom: 13px;
  }
  & .add-okr-progress{
    justify-content: start;
    column-gap: 19px;
    & .okr-input-wrapper{
      display: flex;
      column-gap: 8px;
      align-items: center;
      & .formik-text-input{
        margin: 0px;
        width: 48px;
      }
      & .styled-dropdown{
        width: 145px;
      }
      & span{
        color: $color-gray-dark;
        font-size: $font-size-input;
      }
    }
  }
}