@import "~theme/variables", "~theme/colors";

.container-table-mobile {
  margin-bottom: 100px;
  .generic-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    padding-left: 0;
    margin-top: 5px;
    .container-progress {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
