@import 'theme/variables', 'theme/colors';

.simple-tag{
    //width: 64px;
    margin: 5px;
    text-align: center;
    color: $color-white;
    .text{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 4px 8px 3px 8px;
        cursor: default;
    }
    .small {
        font-size: 14px !important;
        color: $color-gray-dark;
        font-weight: $regular;
    }
}

.primary{
    background-color: var(--color-primary);
}

.secondary{
    background-color: var(--color-secondary);
}

.archived{
    background-color: $color-gray-ae;
}
.soft{
    color: $color-black-text;
    font-weight: $semibold;
    font-size: 14px;
    display: flex;
    height: 26px;
    align-items: center;
    justify-content: center;
    background-color: $color-gray-soft-2;
}