@import "theme/variables", "theme/colors";

.change-leader-container {
	& form {
		margin-bottom: 35px;

		& .change-leader-form {
			row-gap: 30px;

			& .MuiGrid-grid-xs-6 {
				row-gap: 10px;
				display: flex;
				flex-direction: column;
				margin-top: 20px;
			}
		}
		.type-drop-container {
			.type-drop__control,
			.type-drop__option {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.actual-leader {
		margin-left: 6px;
	}

	p + label {
		height: 32px;
		display: flex;
		align-items: flex-end;
		margin-left: 6px;
	}

	div {
		.teams-wrapper {
			min-height: 60px;
			display: grid;
			grid-template-columns: 33% 33% 33%;
			column-gap: 15px;
			row-gap: 25px;
			padding: 20px 0;
			overflow: hidden;

			& span {
				color: $color-gray;
				font-size: $font-size-input;
				font-weight: $semibold;
			}
			& button {
				bottom: 105px;
				position: absolute;
				z-index: 10;
				background-color: transparent;
				border: none;
				color: $color-primary;
				font-weight: $semibold;
				&:hover {
					cursor: pointer;
					opacity: 0.7;
					transition: all 0.2s;
				}
				& svg {
					transform: rotate(180deg);
					stroke: $color-primary;
					margin-right: 6px;
				}
				& .toggle-arrow {
					transform: rotate(0deg);
					transition: all 0.2s;
				}
			}
		}
		.has-more-than-nine-teams {
			height: 135px;
		}
		.see-more-teams {
			height: 100%;
		}
	}

	.modal-button {
		margin-top: 60px;
		position: relative;
		&.disabled {
			opacity: 0.4;
			pointer-events: unset;
			cursor: not-allowed;
		}
		&:hover:not(.disabled) {
			opacity: 0.7;
			transition: all 0.2s;
		}
	}
}
