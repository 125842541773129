@import 'theme/variables', 'theme/colors';
.objective_progress_container {
  margin: $spacing;
  cursor: pointer;
  .due_date {
    display: block;
    color: var(--color-secondary);
    margin-bottom: 4px;
    cursor: pointer;
    &.out_of_date {
      color: $color-error;
    }
  }
  .name {
    display: inline-block;
    margin-bottom: 12px;
    font-weight: $semibold;
    
  }
  hr {
    color: $color-gray-soft;
  }
}
