@import '~theme/variables';

.component-progressbar {
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin-right: 13px;
    font-weight: $semibold;
    white-space: nowrap;
  }
}
