@import "~theme/variables", "~theme/colors";

.container-review-component {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  .container-buttons {
    display: flex;
    flex: 1;
    margin: 0px !important;
    .container-review-component .container-buttons .MuiButtonBase-root {
      margin: 0 !important;
    }
    .MuiButton-text:nth-child(1){
      width: 72px;
      height: 32px;
      border-radius: 16px;
    }
    .MuiButton-text:nth-child(2){
      width: 88px;
      height: 32px;
      border-radius: 16px;
    }
    .button-filter{
      background: $color-gray-f1;
      border-radius: 16px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 16px;
      span {
        color: $color-black-text;
        font-weight: normal;
      }
    }
    .button-filter:hover{
      background: var(--color-primary-soft);
      border-radius: 16px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 16px;
      span {
        color: var(--color-primary);
        font-weight: normal;
      }
    }
    .button-filter-selected {
      background: $color-gray-dark;
      border-radius: 16px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 16px;
      span {
        color: $color-white;
        font-weight: normal;
      }
    }
    .button-filter-selected:hover {
      background: var(--color-primary-soft);
      border-radius: 16px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 16px;
      span {
        color:  var(--color-primary);
        font-weight: normal;
      }
    }
  }
  .container-drop-downs {
    flex: 1;
    display: flex;
  }
}
