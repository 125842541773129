@import '~theme/variables', '~theme/colors';

.component-fixededitheader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: #ffffff;
  padding: 0 24px;

  .header__nav {
    display: flex;
    align-items: center;
  }

  .company__info {
    display: flex;
    align-items: center;
    .logo__container {
      background-color: $color-gray-soft;
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .company__name {
      margin-left: 12px;
    }
  }

  .edit-div {
    display: flex;
    button:last-of-type {
      margin-left: 12px;
    }
  }
}
