@import 'theme/variables', 'theme/colors';

.key-result{
   .formik-text-input {
      .unit-style-light{
         color: $color-gray;
      }
      .unit-style-strong{
         color: $color-black;
      }
   }
}