@import "~theme/variables", "~theme/colors";

.security-container {
  .description-row {
    display: flex;
    font-size: $font-size-p;
    margin-top: 24px;
    margin-bottom: 24px;

    // .description, .buttons{
    //     width: 50%;
    // }

    .description {
      display: flex;
      margin: auto;
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      /* Reponsive design */
      @media only screen and (max-width: 720px) {
        justify-content: center;
      }
      .edit-btn {
        margin-right: 5px;
      }
    }
  }
}
