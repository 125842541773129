@import '~theme/variables', '~theme/colors';
.container-layout{
  .container-header{
    .header__container{
      position: fixed;
      top: 0;
      left: 0;
    }
  }
  .container-both-bar-menus{
    display: flex;
    z-index: 100;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .container-both-bar-menus-height{
     @extend .container-both-bar-menus;
     height: auto;
  }
}