@import '~theme/variables', '~theme/colors';

.component-mobile-item-individual-rate {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing;
  margin-bottom: 20px;

  .item-individualrate-user {
    display: flex;
    gap: 16px;

    .icon {
      font-size: 20px;
      color: $color-gray-icons-color;
    }
    .user-container {
      .user {
        font-size: $font-size-h4;
        font-weight: $medium;
        color: $color-black-text;
      }
      .role {
        color: $color-gray;
      }
    }
  }
  & .wrapper-individual-rating{
    margin-top: -5px;
  }
}