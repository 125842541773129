@import '~theme/variables', '~theme/colors';
.container-objectives-mobile{
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 90px 16px 0px 16px;
  .search-filter-wrapper{
    display: flex;
    margin-bottom: 25px;
    .searchbar-mobile {
      width: 100%;
    }
    .filter-icon{
      color:$color-gray-ae;
      margin: auto 0 auto 16px;
    }
  }
  .container-table{
    margin-bottom: 100px;
    .generic-data-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{
      padding: 0;
      margin-bottom: 6px;
      font-size: 13px;
      font-family: $font-family-primary;
    }
    .rdt_TableHeadRow{
      min-height: 24px;
      border-bottom-width: 2px;
    }
  }
  
}