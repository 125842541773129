@import 'theme/variables', 'theme/colors';

.component-statustag {
  width: 84px;
  height: 26px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    background-color: var(--color-primary);
  }
  &.closed {
    background-color: $color-gray-icons-color;
  }
  
  .status-text {
    font-size: $font-size-p;
    font-weight: $semibold;
    color: $color-white;
  }

  &.small {
    height: 23px;
    width: 56px;
    .status-text {
      font-size: $font-size-p;
      font-weight: $medium;
    }
  }
}
