@import 'theme/variables';

.objectives-key-results-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;

  .add-kr-button{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .key-results-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
    .instructions {
      .instructions-title {
        font-size: 20px;
        font-weight: $semibold;
      }

      .instructions-description {
        font-size: 14px;
      }
    }

    .metrics {
      display: flex;
      flex-direction: column;
      width: 100%;

      .instructions {
        .instructions-title {
          font-size: 16px;
          font-weight: $semibold;
        }

        .instructions-description {
          font-size: 14px;
        }
      }

      .metric-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 12px 0px;
        span {
          font-size: 14px;
        }
        .selector {
          margin-left: 8px;
          width: 120px;
        }
      }

      .metric-values {
        display: flex;
        flex-direction: row;
        width: 100%;
        .values {
          width: 137px;
          margin-right: 16px;
        }
      }      

      .extra-messages {
        min-height: 16px;
        margin-top: 10px;
      }
    }
  }

  .add-key-result {
    display: flex;
    justify-content: flex-end;
    width: 100%;    
  }
}