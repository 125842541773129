@import "~theme/variables", "~theme/colors";

.container-mark-as-closed{
  & .review-title {
    color: $color-black-text;
    font-size: $font-size-tag;
    margin-left: 20px;
  }
  & .tabs-wrapper{
    padding-top: 30px;

    & .review-tabs {
      & button{
        width: 50%;
      }
      & .Mui-selected{
        font-weight: $semibold;
      }
    }

    & .component-rategeneralreview{
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 30px 20px 0 ;
      & .general-rate{
        margin:  0px auto 40px;
      }
      // & .formik-text-area{
      //   max-width: 420px;
      //   min-width: 340px;
      // }
    }
  }
}