@import 'theme/variables', 'theme/colors';

.tags-container {
  .tags-grid {
    margin: 25px 0;

    .label {
      font-size: 16px !important;
      font-weight: $medium;
      margin: auto 0px auto 0px;
    }
    .button {
      text-align: right;
    }
  }

  .tags-archive-label {
    margin-top: 24px;
    span {
      color: $color-gray;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
