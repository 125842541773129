@import 'theme/variables', 'theme/colors', 'theme/fontface';

.tegdu-checkbox {
  display: flex;
  align-items: center;
  font-family: $font-family-primary;
  .checkbox-message {
    font-size: 14px;
    margin-left: 8px;
  }

  .MuiButtonBase-root {
    padding: 0;
  }
}