/* Theme colors  */
// PALETTES 
// Default 
$color-primary: #39D3C0;
$color-primarySoft: #E7FAF8;
$color-primaryLight: #E5F6F5;
$color-secondary: #00A69C;
// Blue 
$color-primary-blue: #7991D1;
$color-primarySoft-blue: #E4E9F6;
$color-primaryLight-blue: #E4E9F6;
$color-secondary-blue: #647AB4;
// Pink 
$color-primary-pink: #E277B0;
$color-primarySoft-pink: #F4DEEA;
$color-primaryLight-pink: #F4DEEA;
$color-secondary-pink: #C75B95;
// Purple 
$color-primary-purple: #716AD0;
$color-primarySoft-purple: #E2E1F6;
$color-primaryLight-purple: #E2E1F6;
$color-secondary-purple: #5F58BA;
// Red 
$color-primary-red: #E0726D;
$color-primarySoft-red: #F9E2E1;
$color-primaryLight-red: #F9E2E1;
$color-secondary-red: #BC4D48;
// Yellow 
$color-primary-yellow: #E9C078;
$color-primarySoft-yellow: #FBF3E4;
$color-primaryLight-yellow: #FBF3E4;
$color-secondary-yellow: #E2B25E;
// GRAY-SCALE 
$color-black: #000000;
$color-black-text: #444444;
$color-gray-dark: #666666;
$color-gray-dark-2: #0000001F;
$color-gray: #999999;
$color-gray-soft: #ECECEC;
$color-gray-soft-2: #E0E0E0;
$color-gray-ae: #AEAEAE;
$color-gray-f1: #F1F1F1;
$color-gray-f7: #F7F7F7;
$color-gray-light: #A5A5A5;
$color-gray-soft-light: #FAFAFA;
$color-gray-hover-light: #F2F2F2;
$color-gray-icons-color: #C5C5C5;
$color-gray-shadow-color: #00000012;
$color-gray-shadow-color-secondary: #0000000D;
$color-yellow-icon: #F4C853;
$color-white: #FFFFFF;
// STATUS 
$color-success: #20CBAD;
$color-warning: #FFD460;
$color-error: #FF5555;
$color-disabled: #EAEAEA;

