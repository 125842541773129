@import '~theme/variables', '~theme/colors';

.card__container {
  position: relative;
  width: 100%;
  max-width: 464px;
  background: $color-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .alert {
    position: absolute;
    top: -80px;
    left: 0;
    width: 100%;
  }

  .logo__container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 27px;
    img {
      width: 89px;
      height: 39px;
      max-width: 188px;
    }
  }

  .domain__info {
    border-bottom: 1px solid (#00000012);
    text-align: center;

    #company {
      font-size: 24px;
      font-weight: $bold;
      margin-bottom: 14px;
    }

    #domain {
      color: $color-gray;
      margin-bottom: 23px;
    }
  }

  .form__container {
    height: 100%;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .inputs-section {
        margin-top: 36px;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .formik-text-input {
          margin-bottom: 44px;

          .MuiFormLabel-root {
            font-size: 14px;
          }

          .formik-styled-error {
            font-size: 14px;
          }
        }
      }
      button {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .checkbox {
      margin-bottom: 31px;

      .MuiFormControlLabel-label {
        font-size: $font-size-p;
      }
    }

    .password__control {
      padding-top: 24px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    //justify-content: flex-end; // TODO: Uncoment when Forgot password is un-available
    align-items: center;

    a {
      color: $color-gray;
      font-size: $font-size-p;
      font-weight: $medium;
      //display: none;
    }

    .footer-btn {
      align-self: flex-end;
      min-width: 50px;
      display: flex;
      justify-content: center;
    }
  }
}
