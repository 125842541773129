@import "theme/colors", "theme/variables";

.container-circularprogress {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .component-circularprogress {
    position: relative;
    display: inline-flex;
    border-radius: 50%;
    color: $color-primary;
    .bottom-circle {
      color: $color-gray-soft;
    }
    .top-circle {
      position: absolute;
      left: 0;
    }
    #span-without-table {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-family: $font-family-secondary;
      font-weight: $bold;
      font-size: 30px;
      line-height: $font-size-p;
    }
    #table-span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 14px;
      color: $color-black-text;
      font-family: $font-family-primary !important;
      line-height: $font-size-p;
    }
  }
  .name-circular{
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
  }
}
