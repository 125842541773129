@import 'theme/colors';

.tegdu-warning-message {
  display: flex;
  align-items: center;
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    background-color: $color-yellow-icon;
    border-radius: 50%;
    font-size: 11px;
    color: $color-white;
  }
  span {
    font-size: 11px;
    margin-left: 10px;
    color: $color-black-text;
  }
}