@import "~theme/variables", "~theme/colors";
.container-bar-progress {
  flex-direction: column;
  display: flex;
  max-height: 50px;
  margin-bottom: 10px;
  .okr-name-and-date {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
    .okr {
      display: flex;
      flex: 1;
      span{
        font-size: 16px;
        font-family: $font-family-primary;
        color: $color-black-text;
        font-weight: $semibold;
      }
    }
    .due-date {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      span{
        font-size: 14px;
        font-family: $font-family-primary;
        color: $color-primary;
      }
    }
  }
  .bar-progress {
    display: flex;
    width: 100%;
    .MuiLinearProgress-root {
      border-radius: 4px;
    }
  }
}
