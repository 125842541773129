@import '~theme/colors';
.tegdu-search-bar {
  
  .MuiOutlinedInput-root {
    border-radius: 20px;
    .MuiInputAdornment-root {
      .MuiSvgIcon-root {
        color: $color-gray;
      }
    }
  }
}