@import '~theme/variables', '~theme/colors';

.onboarding-basicinfo-form {
  width: 100%;
  text-align: left;
  .button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}