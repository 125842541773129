@import '~theme/variables', '~theme/colors';

.toast-notification-container{
    min-width: 280px;
    max-width: 280px;
    padding: 6px 8px;
    border-radius: 5px;
      
    .icon{
        display: flex;               
        .MuiSvgIcon-root{
            display: flex;
            margin: auto;
        }
    }

    .label{
        display: flex;
        font-size: 14px;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.error-toast{
    background-color: $color-error;
}

.success-toast{
    background-color: $color-black-text;
}