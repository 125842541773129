@import "~theme/variables", "~theme/colors";

.container-carousel{
  padding-bottom: 30px;
  div{
    margin-top: 12px;
    font-size: 18px;
    .container-subtitle{
      span{
        color: $color-primary;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}