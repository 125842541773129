@import 'theme/variables', 'theme/colors';

.component-objectiveslogsgroup {
  .container-logs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .container-seemore {
    display: flex;
    margin-top: 9px;
    .seemore {
      display: flex;
      align-items: center;
      height: 32px;
      color: $color-gray;
      cursor: pointer;
      gap: 7px;

      span.caption {
        font-size: $font-size-p;
        font-weight: $semibold;
        text-decoration: underline;
      }
      .MuiButtonBase-root {
        padding: 0;
        color: inherit;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
