@import 'theme/variables', 'theme/colors', 'theme/fontface';

.tegdu-date-picker-container {
  display: flex;
  flex-direction: column;
  font-family: $font-family-primary;
  .label-message {
    font-size: 16px;
    color: $color-black-text;
    font-weight: $semibold;
    margin-bottom: 4px;
  }
}


.tegdu-date-picker {
  display: flex;
  align-items:center ;
  height: 32px;
  border: 1px solid $color-gray;
  border-radius: 4px;
  width: 100%;
  font-family: $font-family-primary;

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
    color: $color-gray-dark;
    margin-right: 3px;
    background: transparent;
  }

  &.edit-mode {
    border: 1px solid $color-white;
  }

  .react-datepicker-wrapper {
    height: inherit !important;    
    overflow-x: hidden;
    .react-datepicker__input-container {
      display: flex;
      align-items: center;
      height: inherit;      
      overflow-x: hidden;
      input {
        font-family: "Nunito";
        font-size: 14px;
        height: 19px !important;
        border: none;        
        padding: 7px 8px 6px;        
        border-radius: 4px;  
        outline: none;    
        width: 100%; 
      }
    }
  }
  .react-datepicker {
    
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    background-color: $color-white;
    transition: all 0.2s ease;
    cursor: pointer;
    .react-datepicker__triangle {
       display: none;
    }

    .react-datepicker__month-container {
      
      .react-datepicker__header {
        background-color: $color-white ;
        border-bottom: none;

        .react-datepicker__day-names {
          font-size: 12px;
        }
      }

      .react-datepicker__month {
        font-size: 12px;
        .react-datepicker__week {
          .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
            background-color: var(--color-secondary);
            outline: none;
          }
        }
      }
    }
  }

  .date-view-mode {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    span {
      padding: 7px 8px 6px;     
    }
    
  }
}

