@import '~theme/colors';

.component-colorpicker {
  margin-left: -5px;
  display: flex;
  #check-icon-colors{
    font-size: 27px;
  }
  #icon-colors-before{
    font-size: 32px;
  }
  .MuiRadio-root {
    padding: 0;
    &.colorpicker-default {
      color: $color-primary;
    }
    &.colorpicker-blue {
      color: $color-primary-blue;      
    }
    &.colorpicker-pink {
      color: $color-primary-pink;      
    }
    &.colorpicker-purple {
      color: $color-primary-purple;      
    }
    &.colorpicker-red {
      color: $color-primary-red;      
    }
    &.colorpicker-yellow {
      color: $color-primary-yellow;      
    }
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
}