@import 'theme/variables', 'theme/colors';

.unarchive-pillars {
  h1.archive-title {
    margin: $spacing 0px;
    color: $color-gray-ae;
  }
  h3.archive-description {
    padding-bottom: $spacing;
    margin-bottom: $spacing;
    border-bottom: 1px solid $color-gray-soft;
  }
}