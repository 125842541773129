@import '~theme/variables', '~theme/colors';
[data-rsbs-overlay], [data-rsbs-backdrop]{
  z-index: 100;
}

.bottom-sheet{
  height: auto;
}
.reviews-bottom-sheet-container{
  height: 450px;
  display: flex;
  margin: 10px 16px 0px 16px;
  flex-direction: column;
  .header-sheet{
    display: flex;
    flex: 1;
    div:nth-child(1){
      flex: 1;
      span{
        font-size: 18px;
        font-weight: $semibold;
        color: $color-black-text;
      }
    }
    div:nth-child(2){
      flex: 1;
      display: flex;
      justify-content: flex-end;
      span{
        font-size: 18px;
        font-weight: $medium;
        color: $color-black-text;
        text-decoration: underline;
      }
    }
  }
  .content-sheet{
    flex: 8;
    .role-content{
      .title-content{
        margin: 18px 0 12px;
        span{
          font-size: 16px;
          font-weight: $medium;
        }
      }
      .buttons-content-filters{
        .container-buttons-filters{
          height: 40px;
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          column-gap: 20px;
          .button-filter-selected{
            padding: 10px;
            height: 32px;
            color: $color-white;
            background-color: $color-gray-dark;
            border-radius: 16px ;
            font-weight: normal ;
            font-size: 14px;
            font-family: $font-family-primary;
            .MuiButton-label{
              font-weight: $regular;
            }
          }
          .button-filter{
            padding: 10px ;
            height: 32px;
            color: $color-black-text;
            background-color: $color-gray-f1;
            border-radius: 16px;
            font-size: 14px;
            font-family: $font-family-primary ;
            .MuiButton-label{
              font-weight: $regular;
            }
          }
          .container-icon-filter{
            margin-left: 5px;
            .MuiSvgIcon-root{
              color:$color-gray-ae ;
            }
          }
        }
      }
    }
    & .divider{
      width: 14px;
      border-top: 2px solid $color-gray-ae;
      border-right: 0px; 
      border-left: 0px; 
      border-bottom: 0px; 
      margin: auto;
    }
    & .mobile-reviews-score-filter{
      & .score-wrapper{
        margin-top: 12px;
        display: flex;
        align-items: center;
        & > .tegdu-select {
          width: 45%;
          & .MuiSvgIcon-root{
            margin-right: 6px;
            color: $color-warning;
            font-size: $font-size-input;
          }
          & .tegdu-select-container .tegdu-select-prefix__control{
            flex-direction: row-reverse;
            & .tegdu-select-prefix__indicators{
              flex-direction: row-reverse;
              margin-left: 5px;
              align-items: center;
              & .MuiSvgIcon-root{
                margin-right: 6px;
                color: $color-warning;
                font-size: $font-size-input;
              }
              & .tegdu-select-prefix__indicator-separator{
                width: 5px;
                border-top: 1px solid $color-black-text; 
                border-right: 0px;
                border-left: 0px;
                border-bottom: 0px;
                margin: auto;
              }
            }
            & .tegdu-select-prefix__single-value{
              display: flex;
              & .MuiSvgIcon-root{
                font-size: $font-size-tag;
              }
            }
          } 
        }
      }
    }
    & .mobile-reviews-date-filter{
      margin-top: 36px;
      & .datepicker-wrapper {
        margin-top: 12px;
        display: flex;
        align-items: center;
        position: relative;
        & .tegdu-date-picker-container{
          width: 45%;
          & .tegdu-date-picker {
            padding-right: 5px;
            height: 38px;
          }
        }
        & .error-label{
          position: absolute;
          top: 42px;
          left: 5px;
          color: $color-primary-red;
          font-size: $font-size-p;
        }
      }
    }
  }
}