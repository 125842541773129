@import 'theme/variables', 'theme/colors';

.container-members {
  .title-container{
    display: flex;    
    justify-content: space-between;
  }
  .card-container {
    /* PROVISIONAL VALUES */
    // TODO: Si todos los cards tienen el mismo radius habrá que poner ese valor global
    
    border-radius: 12px;

    #column-1{
      width: 100%;
      display: flex;
      div{
        margin: auto 0px auto 45px;
      }
    }
  }
}
