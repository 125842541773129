@import 'theme/variables', 'theme/colors';

.invitations-container{
    .grid-title{
        & .title{
            display: flex;
            justify-content: space-between;
            max-width: unset;
            flex-basis: unset;
        }
    }
    .appbar-container{
        .MuiTab-textColorInherit.Mui-selected{
            color: var(--color-secondary);
        }
        .all-tabs{
            margin-top: 42px;
            margin-bottom: 50px;
            #requests{
                min-width: 90px;
            }
            #pending{
                min-width: 90px;
            }
            #accepted{
                min-width: 90px;
            }
            
        }
        .container-filters{
            width: 311px;
            margin-bottom: 24px;
        }
      }
}