@import '~theme/variables', '~theme/colors';
.loader-container{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.alert {
  position: absolute;
  top: 20px;
  left: 10px;
  right: 10px;
}

.container-login-container {
  display: flex;
  padding: 0px 24px 0px 24px;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  .logo-container{
    display: flex;
    min-height: 170px;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 15px;
    img{
      width: 150px;
    }
  }
  .title-workspace{
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    span{
      color: $color-black-text;
      font-size: 24px;
      font-family: $font-family-secondary;
      font-weight: $bold;
    }
  }
  .title-organization{
    min-height: 50px;
    display: flex;
    font-size: 14px;
    color: $color-gray;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .form-email-password{
    display: flex;
    min-height: 300px;
    align-items: flex-end;
  }
  .dont-have-account{
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: $font-family-primary;
    font-weight: $semibold;
    span{
      color: $color-black-text;
      b{
        color:var(--color-primary);
        text-decoration: underline;
      }
    }
    
  }
}
