@import '~theme/colors', '~theme/variables';

.component-sectiondivider {
  width: 100%;
  & > h4 {
    padding-bottom: 2px;
    margin-bottom: $spacing;
    color: $color-gray-dark;
    border-bottom: 1px solid rgba($color-black, 0.12);
  }
}