@import 'theme/variables', 'theme/colors';

.tegdu-menu-datepicker {
  height: 22px !important;
  .tegdu-select {
    height: inherit;
    .tegdu-select-container {
      height: inherit;
      .tegdu-select-prefix__control {
        min-height: 22px !important;
        font-size: 12px;
        border-color: $color-white;
        .tegdu-select-prefix__value-container {
          height: 12px;
        }
        .tegdu-select-prefix__indicators {
          height: inherit;
          .MuiSvgIcon-root {
            color: var(--color-primary) !important;
          }
        }
      }
    }
    .tegdu-select-prefix__menu {
      font-size: 12px;
      .tegdu-select-prefix__menu-list {
        .tegdu-select-prefix__option{
          text-overflow: ellipsis;
          overflow-x: hidden;
        }

        .tegdu-select-prefix__option--is-focused {
          background-color: $color-gray-soft;
        }
        .tegdu-select-prefix__option--is-selected {
          background-color: $color-gray-soft;
          color: $color-black;
        }
      }
    }
  }
}