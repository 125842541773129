@import '~theme/variables', '~theme/colors';

$open-sidebar-width: 256px;

.toolbar.open {
  margin: 100px auto 0px;
  white-space: nowrap;
}

.toolbar.close {
  display: flex;
  align-items: center;
  margin-top: 2px !important;
  height: 64px;
  max-height: 64px;
}

.content {
  display: block;
  height: 100%;
  
  &.open {
    margin-left: $open-sidebar-width;
    transition: margin-left 0.4s;
    transition-timing-function: ease;
  }
  &.close {
    margin-left: 50px;
    transition: margin-left 0.4s, background-color 0.9s, color 0s;
    transition-timing-function: ease;
  }
  &:not(.nopadding) {
    padding: 104px 24px 40px;
  }
}

.drawer {
  width: $open-sidebar-width;
  flex-shrink: 0;
  white-space: 'nowrap';
  overflow-x: hidden;
}

.sidebar__drawer,
.drawer {
  margin-top: 67px;
  .menu__options:hover {
    background-color: $color-gray-soft !important;
    .MuiListItem-button:hover{
      background-color: transparent !important;
    }
  }
  .selected{
    background-color: $color-gray-soft !important;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 17px;
    color: $color-gray-icons-color;
  }
  &.open {
    background-color: $color-gray-soft-light !important;
    width: $open-sidebar-width;
    //transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 0.4s;
    transition-timing-function: ease;
    color: $color-black-text;
    overflow-x: hidden;
    .icon {
      margin-left: 24px !important;
    }
  }
  &.close {
    width: 50px;
    color: $color-black-text;
    background-color: $color-gray-soft-light !important;
    overflow-x: hidden;
    transition: width 0.4s, background-color 0.9s, color 0s;
    transition-timing-function: ease;
    .icon {
      margin-left: -5px;
    }
  }

  .list__item {
    // border-left: 5px solid transparent;
    border-bottom: 0 !important;
    white-space: nowrap;

    &.selected {
      border-left-style: solid;
    }
  }
}
.sidebar__drawer {
  &.toursidebar {
    z-index: 100000;
    background-color: transparent !important;

    .hidemenu {
      opacity: 0;
      & > * {
        cursor: default;
      }
    }
    .selected {
      border-radius: 10px;
    }
  }
  &.open .list__item.selected {
    border-left-color: rgba($color-white, 0.8);
  }

  &.close .list__item.selected {
    border-left-color: var(--color-primary);
  }
}
.sidebar__item__text {
  max-height: 20px;

  & > span {
    font-size: $font-size-p;
    font-family: $font-family-primary;
  }

  &.opacity > span {
    opacity: 0;
  }
}
.sidbar__footer {
  position: absolute;
  bottom: 64px;
  left: 0;
  right: 0;

  .logo__container {
    padding: 16px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #8080801a;
    img {
      max-width: 73px;
    }
  }
  .logout.open {
    font-size: $font-size-xs;
    font-weight: $bold;
    margin-bottom: 26px;
    z-index: 100;
    span {
      color: $color-white;
      cursor: pointer;
    }
    span:link {
      text-decoration: none;
    }
    span:active,
    span:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}

.sidebar__responsive{
  display: flex;
  .MuiDrawer-paper{
    display: flex;
    width: 225px;
    background-color: $color-gray-soft-light;
    max-height: 100vh;
  }

  .sidebar-resp-content{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .username-container{      
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 10px;
      cursor: pointer;
      .circle{
        margin-left: auto;
        margin-right: auto;        
      }
      .name{
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        text-decoration: underline;
      }
    }

    .options{
      height: 100%;      
      .menu__options{
        .list__item{
          .icon{
            color: $color-gray-icons-color;
            margin-right: 15px;
          }    
        }
      }  
    }

    .footer{      
      margin-top: auto;
      .menu__options{
        .list__item{
          .icon{
            color: $color-gray-icons-color;
            margin-right: 15px;
          }    
        }
      }  

      .logo__container{
        display: flex;
        justify-content: center;
        padding: 16px;
      }
    }
  }
}

/* Reponsive design */

/* Devices with 415px and UP (min-width: 416px) */

@media only screen and (min-width: 415px) and (max-width: 992px){
  .sidebar__drawer.open{
    display: none;
  }

  .content.open{
    margin-left: 0px;
    transition: margin-left 0.4s, background-color 0.9s, color 0s;
    transition-timing-function: ease;
  }
}

@media only screen and (max-width: 414px){
  .sidebar__drawer.open{
    display: none;
  }

  .content.open{
    margin-left: 0px;
    transition: margin-left 0.4s, background-color 0.9s, color 0s;
    transition-timing-function: ease;
  }
}