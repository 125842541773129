@import "~theme/variables", "~theme/colors";
.active-dot{
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: $color-primary;
  margin-right: 5px;
}
.inactive-dot{
  width: 8px;
  margin-right: 5px;
  height: 8px;
  border-radius: 50px;
  background-color: $color-gray-ae;
}
