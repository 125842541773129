/* Theme spacing variables */
$spacing: 24px;
$spacing-2x: $spacing * 2;
$spacing-3x: $spacing * 3;
$spacing-4x: $spacing * 4;
$spacing-half: $spacing / 2;

/* Theme typography variables */
// Font family
$font-family-primary: 'Nunito';
$font-family-secondary: 'Quicksand';
// Font sizes
$font-size-h1: 24px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: 16px;
$font-size-tag: 18px;
$font-size-input: 14px;
$font-size-p: 14px;
$font-size-s: 12px;
$font-size-xs: 10px;
$font-size-label: 16px;
// Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;
