@import '~theme/variables', '~theme/colors';

button.menuDotsIcon {
  padding: 6px;
  border-radius: 8px;
  color: $color-gray;

  &:hover {
    color: var(--color-primary);
  }
}
