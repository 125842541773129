@import '~theme/variables', '~theme/colors';

.c-stepcontent {
  h2 {
    margin-bottom: 10px;
    color: $color-black-text;
  }
  
  .step-content-text {
    font-size: 18px;
  }
}
