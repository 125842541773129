@import "theme/variables", "theme/colors";

.objectives-details-content {
  .back-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing;
  }
  .simple-tag {
    margin: 0;
  }
  .component-objectivedetails {
    margin-bottom: 20px;
  }
  .detailscontent-roles {
    .roles-group {
      display: flex;
      flex-direction: column;
      gap: $spacing;
    }
  }
  .detailscontent-teams {
    .teams-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
  .detailscontent-krs {
    margin-top: $spacing;
    .keyresults-group {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing;
    }
  }
  .detailscontent-logs {
    margin: $spacing 0 40px;
  }
  .detailscontent-comments{
    margin: $spacing 0 0;
  }
  .details-teammate-rates {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .details-title-review {
      border-bottom: solid $color-gray-soft;
      border-bottom-width: 1px;
      display: flex;
      align-items: center;
      h1 {
        color: $color-gray-dark;
        font-size: 16px;
      }
      .MuiButton-text {
        background-color: transparent;
        color: var(--color-primary);
      }
    }
    .container-rates {
      margin-top: 15px;
      grid-gap: 15px 147px;
      display: flex;
      overflow: hidden;
      height: 120px;
      flex-wrap: wrap;
      flex-direction: row;
    }
    .container-rates-more {
      margin-top: 15px;
      grid-gap: 15px 147px;
      display: flex;
      height: auto;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}
