@import "~theme/variables", "~theme/colors";

.container-buttons-filters-status {
  height: 40px;
  display: flex;
  margin-bottom: 30px;
  gap: 10px;
  align-items: center;
  .button-filter-selected {
    padding: 10px !important;
    height: 32px;
    color: $color-white;
    background-color: $color-gray-dark !important;
    border-radius: 16px !important;
    font-weight: normal !important;
    font-size: 14px;
    font-family: $font-family-primary !important ;
    .MuiButton-label {
      font-weight: normal !important;
    }
  }
  .button-filter {
    padding: 10px !important;
    height: 32px;
    color: $color-black-text;
    background-color: $color-gray-f1;
    border-radius: 16px !important;
    font-size: 14px;
    font-family: $font-family-primary !important ;
    .MuiButton-label {
      font-weight: normal !important;
    }
  }
  .container-icon-filter {
    margin-left: 5px;
    .MuiSvgIcon-root {
      color: $color-gray-ae;
    }
  }
}

