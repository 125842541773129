@import '~theme/variables', '~theme/colors';

.container-button-to-select{
  width: 160px;
  height: 94px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-f7;
  flex-direction: column;
  #icon-select{
    font-size: 32px;
    margin-bottom: 12px;
  }
  #txt-select{
    font-size: 16px;
    font-family: $font-family-primary;
    font-weight: $semibold;
    color: $color-black-text;
  }
}