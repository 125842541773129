/* PRIMARY: Nunito */
// Regular
@font-face {
  font-family: $font-family-primary;
  src: url('./fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: $regular;
  font-display: auto;
  font-style: normal;
}

// Bold
@font-face {
  font-family: $font-family-primary;
  src: url('./fonts/Nunito/Nunito-Bold.ttf') format('truetype');
  font-weight: $bold;
  font-display: auto;
  font-style: normal;
}

// SemiBold
@font-face {
  font-family: $font-family-primary;
  src: url('./fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
  font-weight: $semibold;
  font-display: auto;
  font-style: normal;
}

/* SECONDARY: Quicksand */
// Bold
@font-face {
  font-family: $font-family-secondary;
  src: url('./fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: $bold;
  font-display: auto;
  font-style: normal;
}

