@import '~theme/colors', '~theme/variables';

.forgot-password-form {
  display: flex;
  flex-direction: column;
  #label-email {
    font-size: 16px;
    font-family: Nunito, serif;
  }
  .button-continue {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    .MuiButton-label {
      display: flex;
      justify-content: center;
      font-weight: $semibold;
      font-size: 14px;
    }
  }

}
