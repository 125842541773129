@import "~theme/variables", "~theme/colors";

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .name-title{
        margin-left: 24px;
        padding-top: 2px;
    }

    .text-title{
        padding-top: 2px;
    }

    .unfold-icon{
        height: 20px;
        width: 20px;
    }
}


.name-container{
    display: flex;
    flex-direction: row;
    .expand-button{
        margin-right: 20px;
        .expand-more{
            height: 30px;
            color: $color-gray;
            width: 30px;
        }
    }
    .name-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background-color: #EAEAEA;
        height: 26px;
        width: 128px;
    }
}

