@import 'theme/variables', 'theme/colors';

$transition-time: all 0s ease 0s;

.styled-dropdown {
  .description {
    margin: 4px 0;
  }
  .type-drop-container {
    min-height: 32px;

    .timeframe-objective-options {
      .dates-range {
        color: #919191;
        font-size: 12px;
        margin-left: 10px;
      }
    }
    &.border-transparent{
      .type-drop__control {
        border-color: transparent !important;
      }
      .type-drop__value-container {
        justify-content: right !important;
      }
    }

    .type-drop__control {
      border-color: #aeaeae;
      color: $color-black-text;
      font-size: 14px;
      min-height: 32px;
      transition: $transition-time;
      padding: 1px 0;

      &:hover:not(.type-drop__control--is-focused) {
        border-color: $color-black;
      }

      .type-drop__value-container {
        transition: $transition-time;
        padding-top: 0;
        padding-bottom: 0;

        .type-drop__single-value {
          margin-left: 0;
          width: 100%;
        }

        .type-drop__multi-value {
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
      .type-drop__value-container--has-value {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .type-drop__indicators {
        .type-drop__indicator-separator {
          background-color: transparent;
        }
        .type-drop__dropdown-indicator {
          padding-top: 6px;
          padding-bottom: 6px;
        }
        .type-drop__clear-indicator {
          padding-top: 0;
          padding-bottom: 0;
        }
        .MuiSvgIcon-root {
          transition: $transition-time;
          color: $color-gray;
        }
      }
    }

    &.error .type-drop__control {
      border-color: $color-error;
    }

    .type-drop__control--is-focused {
      border-color: var(--color-primary);
      box-shadow: none; // TODO: REVISAR ESTE PROP
    }

    .type-drop__menu {
      font-size: 14px;
      .type-drop__menu-list {
        .type-drop__option{
          text-overflow: ellipsis;
          overflow-x: hidden;
        }

        .type-drop__option--is-focused {
          background-color: $color-gray-soft;
        }
        .type-drop__option--is-selected {
          background-color: $color-gray-soft;
          color: $color-black;
        }
      }
    }
  }

  .type-drop-container.view-mode {
    .type-drop__control--is-disabled {
      transition: $transition-time;
      border-color: transparent;
      border-left-width: 0;
      background-color: transparent;

      .type-drop__value-container {
        transition: $transition-time;
        padding: 0;
      }

      .type-drop__indicators .MuiSvgIcon-root {
        transition: $transition-time;
        color: transparent;
      }

      .type-drop__single-value--is-disabled {
        color: $color-black-text;
        margin: 0;
      }

      .type-drop__multi-value__remove{
        display: none;
      }
      .type-drop__multi-value__label{
        padding-right: 6px;
      }
    }
  }
}
