@import "theme/variables", "theme/colors";
.notifications-container{
  padding-top: 25px;
  max-width: 640px;
}

.spinner-and-error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  height: 50vh;
}