@import 'theme/variables', 'theme/colors';

.modal-edit-tag{
    .MuiDialog-paper{
        border-radius: 12px;
    }
    .content{
        padding: 0px !important;
        .edit-tag{
            .title{
                margin-top: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid $color-gray-soft;
                text-align: center;
                color: $color-black-text;
                p{    
                    font-weight: $semibold;
                    font-size: $font-size-h1;
                }
            }
            .form-container{
                padding: 24px;

                .objectives-section{
                    margin-top: 40px;
                    #objectives-title{
                        font-size: $font-size-h4;
                        padding-bottom: 12px;
                    }
                }

                .objectives{
                    border-top: 1px solid $color-gray-soft;
                    border-bottom: 1px solid $color-gray-soft;
                    height: 100px;
                    overflow-y: scroll;
                    p{
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                }

                .empty{
                    overflow-y: hidden !important;
                    text-align: center;
                    p{
                        margin-top: 30px;
                    }
                }

                .buttons{
                    display: flex;                    
                    margin-top: 40px;
                    
                    .start-btns{
                        width: 50%;
                        display: flex;
                        justify-content: flex-start;
                    }

                    .end-btns{
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                        .cancel-btn{
                            margin-right: 5px;
                        }
                    }                    
                }
            }
        }
    }
}