@import 'reset', 'variables', 'colors', 'palettes', 'fontface', 'animations', 'typography';

body {
  background-color: $color-white;
  color: $color-black-text;
}

.screen-title {
  color: var(--color-secondary);
  margin-bottom: $spacing;
}

.screen-title-flex {
  margin-bottom: $spacing;
  display: flex;
  justify-content: space-between;

  h1 {
    color: var(--color-secondary);
  }
}

.screen-description {
  margin-bottom: $spacing;
}

.narrow-container {
  width: 100%;
  max-width: 645px;
  margin: 0 auto;
}

.global-textfield {
  .MuiOutlinedInput-root {
    padding: 7px 8px;
    height: 80px !important;
    font-size: 14px !important;
  }
}
button {
  .MuiIcon-root {
    margin-right: 8px;
    font-size: 24px;
  }
}

.grouped-buttons {
  button:not(:last-of-type) {
    margin-right: $spacing-half;
  }
}
.formik-text-area .text-area {
  border: 1px solid #aeaeae;
  border-radius: 4px;
  &:focus {
    border-color: var(--color-primary);
  }
}

// Table loader
.MuiCard-root {
  .loader {
    margin: 40px 0 40px;
    text-align: center;
    align-items: center;
  }
}
// .main-container {
//   max-width: 1000px;
//   margin: auto;
// }

// Screen loader
.screen-loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  p {
    font-size: $font-size-tag;
  }
}

.empty-screen {
  min-height: 200px;
  text-align: center;
  display: flex;
  #message {
    margin: auto;
    font-size: $font-size-tag;
  }
}

.general-snackbar {
  padding: 0px !important;
  border-radius: 0px;
  background-color: transparent !important;
  box-shadow: none !important;
}

.text-outdated {
  color: $color-error;
}
