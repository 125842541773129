@import 'theme/variables', 'theme/colors';

.filters-overview-general{
    margin-bottom: 25px;
    .filters-overview-container{
        display: flex;
        .result-preview{
            font-size: 20px;
            color: $color-gray-dark;
        }
        .clear-button{
            color: var(--color-secondary);
            align-self: flex-end;
            text-align: right;
            p{
                cursor: pointer;
                margin-right: 20px;
                span{
                    margin-right: 10px;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }
}

.hide{
    display: none;
}