@import "theme/variables", "theme/colors";

.container-rates-main{
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  .container-rate-by-teammate {
    display: flex;
    .name-grader {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      color: $color-gray;
      span:nth-child(1) {
        margin-bottom: 5px;
        font-size: 12px;
        b {
          font-size: 16px;
          color: $color-black-text;
        }
      }
      span:nth-child(2) {
        font-size: 12px;
      }
    }
    .rate-starts-date {
      .MuiGrid-item:nth-child(1) {
        height: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .MuiGrid-item:nth-child(2) {
        margin-top: 2px;
        color: $color-gray;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .container-comments-rate {
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    position: relative;
    .show-more-message{
      display: flex;
      flex-direction: row;
      height: 65px;
      overflow: hidden;
      span {
        color: $color-gray;
        b {
          color: $color-black-text;
        }
      }
    }
    .show-more-message-more{
      display: flex;
      flex-direction: row;
      span {
        color: $color-gray;
        b {
          color: $color-black-text;
        }
      }
    }
    .view-more-comment {
      color: $color-gray;
      cursor: pointer;
      font-size: 40px;
      padding-top: 12px;
    }
  }
}