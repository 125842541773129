@import "theme/variables", "theme/colors";

.modal-objective-edit-mobile {
  @keyframes animationHeader {
    from {
      color: $color-white;
      box-shadow: 0px 0px 0px $color-gray-f1;
    }
    to {
      box-shadow: 0px 2px 10px $color-gray-f1;
      color: $color-black-text;
    }
  }
  .screen-loader-modal{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-modal-mobile {
    width: 100%;
    height: 56px;
    position: sticky;
    top: 0;
    display: flex;
    padding: 0 16px 0 16px;
    background-color: $color-white;
    .close-header {
      margin-right: 16px;
      align-items: center;
      display: flex;
      .MuiSvgIcon-root {
        fill: $color-black-text;
        font-size: 24px;
      }
    }
    .title-objective-header {
      align-items: center;
      display: flex;
      span {
        font-size: 18px;
        font-weight: $semibold;
        color: $color-black-text;
      }
    }
  }
  .header-modal-mobile-no-top {
    @extend .header-modal-mobile;
    box-shadow: 0px 2px 10px $color-gray-f1;
    animation-name: animationHeader;
    animation-duration: 1s;
    z-index: 999;
  }
  .container-information-objective {
    width: 100%;
    display: flex;
    padding: 16px 16px 0 16px;
    flex-direction: column;
    .container-icon-title-objective{
      display: flex;
      height: 51px;
      align-items: center;
      div{
        width: 58px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background-color: $color-gray-soft;
      }
      span{
        margin: 0 10px 0 24px;
        color: $color-black-text;
        font-size: 20px;
        font-weight: $semibold;
      }
    }
    .container-descr-date-progress{
      display: flex;
      flex-direction: column;
      margin: 25px 0 0 0;
      gap: 13px;
      .content-in{
        b{
          font-size: 14px;
          font-weight: $semibold;
          color: $color-black-text;
        }
        span{
          font-size: 14px;
         color: $color-gray; 
        }
      }
      
    }
  }
}