@import "~theme/variables", "~theme/colors";
.pwa-general-snackbar {
  justify-content: center;
  margin-bottom: 60px;
}
.reviews-mobile-container{
  margin: 90px 16px 0px 16px;
  padding-bottom: 1px;

  .search-filter-wrapper {
    display: flex;
    margin-bottom: 25px;
    .searchbar-mobile{
      width: 100%;
    }
    .filter-icon{
      color:$color-gray-ae ;
      margin: auto 0 auto 16px;
    }
  }
  .screen-loader{
    height: 60vh;
  }
  .column-title {
    text-transform: uppercase;
  }
  
  .row-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .generic-data-table {
    .rdt_TableBody {
      .rdt_TableCell {
        overflow-x: hidden;
      }
    }
  }
}
.contentModalDetailsObjectives{
  padding: 0;
}
.contentModalDetailsObjectivesHeader{
  padding: 16px;
}
