@import "theme/variables", "theme/colors";

.individual-notification{
  padding: 12px 20px 8px;
  width: 350px;
  max-height: 120px;
  display: flex;
  column-gap: 8px;
  text-decoration: none;

  & .icon{
    color: $color-gray;
  }
  &:hover {
    background-color: $color-gray-f1;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
  }
  & .notification-info {
    .description{
      color: $color-gray-dark;
      margin-bottom: 4px;
      & .user{
        font-weight: $bold;
      }
    }
    & .date{
      color: $color-secondary;
    }
  }
}
.notification-opened{
  background-color: $color-white;
}
.new-notification{
  background-color: $color-primarySoft;
}