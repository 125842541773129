@import 'theme/variables', 'theme/colors';

.screen-onboarding{
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;

   .onboarding-header{
      border-bottom: 2px solid $color-gray-soft;
      height: 64px;
      width: 100%;
   }

   h1 {
      color: var(--color-secondary);
      font-size: 40px;
      margin-bottom: 16px;
   }

   .onboarding-containers {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: $spacing;
   }
}