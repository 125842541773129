@import '~theme/variables', '~theme/colors';

.container-objectives-mobile{
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 90px 16px 0px 16px;
  .container-buttons-filters{
    height: 40px;
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    .button-filter-selected{
      padding: 10px !important;
      height: 32px;
      color: $color-white;
      background-color: $color-gray-dark;
      border-radius: 16px !important;
      font-weight: normal !important;
      font-size: 14px;
      font-family: $font-family-primary !important;
      .MuiButton-label{
        font-weight: normal !important;
      }
    }
    .button-filter{
      padding: 10px !important;
      height: 32px;
      color: $color-black-text;
      background-color: $color-gray-f1;
      border-radius: 16px !important;
      font-size: 14px;
      font-family: $font-family-primary !important;
      .MuiButton-label{
        font-weight: normal !important;
      }
    }
    .container-icon-filter{
      margin-left: 5px;
      .MuiSvgIcon-root{
        color:$color-gray-ae ;
      }
    }
  }  
}