@import 'theme/variables', 'theme/colors';

.component-objectivedetails {
  label {
    span {
      color: $color-gray-dark;
      &.objectivedetails-label-nodata {
        color: $color-gray;
        font-weight: $regular;
      }
      &.timeframe-dates {
        margin-left: 6px;
        font-size: $font-size-s;
        color: $color-gray;
        font-weight: $regular;
      }
    }
  }
  p.progress-text {
    font-weight: $semibold;
    span {
      color: $color-gray-dark;
    }
  }
  .objectivedetails-header {
    gap: 16px;
    &.nowrap {
      flex-wrap: nowrap;
    }
    @media only screen and (min-width: 1000px) {
      flex-wrap: nowrap;
    }
    .container-image-title {
      display: flex;
      align-items: center;
      gap: 16px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        min-width: 48px;
        background-color: $color-gray-soft;
        border-radius: 2px;
      }
      h2 {
        color: $color-black-text;
      }
    }
    .container-actionbuttons {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
  .component-progressbar {
    max-width: 66%;
    margin-bottom: 8px;
  }
  .container-tags {
    gap: 8px;
  }
}
