@import '~theme/variables', '~theme/colors';

.container-bottom-menu{
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: $color-gray-f7;
  .menu-each-one{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-gray-icons-color;
    .hidden-icon-plus{
      display: none;
    }
  }
  .menu-each-one:nth-child(3){
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .MuiIcon-root{
      color: var(--color-primary);
      position: absolute;
      top: -15px;
      font-size: 45px;
    }
  }
}