@import "~theme/variables", "~theme/colors";

.personal-info-form {
  .description-row {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    .text-description {
      font-size: $font-size-p;
      margin: auto;
    }
    .edit-buttons {
      .buttons-container {
        display: flex;
        justify-content: flex-end;

        /* Reponsive design */
        @media only screen and (max-width: 428px) {
          justify-content: center;
        }
        .edit-btn {
          margin-right: 12px;
        }
      }
    }
  }

  .role {
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: 600;
    #role-light {
      font-weight: 200;
      font-size: $font-size-p;
    }
  }

  .teams-row {
    margin-top: 24px;
    p {
      font-size: $font-size-h4;
      font-weight: 600;
    }
  }
}
