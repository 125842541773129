.fade-in {
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeIn 0.3s; /* Firefox < 16 */
  -ms-animation: fadeIn 0.3s; /* Internet Explorer */
  -o-animation: fadeIn 0.3s; /* Opera < 12.1 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 0.5s;
  -webkit-animation: fadeOut 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeOut 0.5s; /* Firefox < 16 */
  -ms-animation: fadeOut 0.5s; /* Internet Explorer */
  -o-animation: fadeOut 0.5s; /* Opera < 12.1 */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
