.InvalidScreen-container{
  display: flex;
  text-align: center;

  .instructions-row{
    text-align: left;
  }

  .logo-row, .img-row{
    margin-bottom: 1rem;
  }
  h2{
    font-size: 30px;
    font-weight: bold;
  }
  h3{
    font-weight: bold;
  }
  h5{
    font-size: 16px;
    font-weight: normal;
  }
  .button-full{
    width: 12%;
  }
}