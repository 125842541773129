@import '~theme/variables', '~theme/colors';

.titleEmail__container {
  text-align: center;
  margin-top: 30px;
}
.titleCreatePass__container h1 {
  color: $color-secondary;
}
h2 {
  font-weight: $extrabold;
  color: var(--color-primary);
}
.instructionsEmail__container {
  margin-top: 25px;
  margin-bottom: 25px;
  color: $color-gray-dark;
}

.titleCreatePass__container {
  text-align: center;
  margin-bottom: $spacing;
}

.instructionsCreatePass__container {
  margin-bottom: $spacing;
}
.cancel__btn {
  margin-right: 10px;
}

.formik-text-input{
  margin-bottom: 20px;
}

.newpassword-footer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 40px;
}

.MuiGrid-root.password-checkboxes {
  margin-bottom: $spacing;
}