@import 'theme/variables', 'theme/colors';

.add-member-modal{
  .MuiDialog-container{
    .MuiPaper-root{
      width: 480px;
      min-height: 473px;
      border-radius: 12px;
      .add-member-modal-content{
        padding: 24px;
        .close-icon{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-end;
          .MuiSvgIcon-root{
            cursor: pointer;
          }
        }
        .title{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding-bottom: 24px;
          border-bottom: 1px solid $color-gray-soft;
          .MuiSvgIcon-root{ 
            color: var(--color-primary);
            width: 48px;
            height: 48px;
          }
          span{
            font-size: 24px;
          }
        }
        .inputs{
          padding-top: 24px;
        }
      }
    }
  }
}