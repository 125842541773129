@import "~theme/variables", "~theme/colors";

.modal-objective-key-results-mobile {
  .container-modal-results {
    .container-header {
      padding: 0 16px 0 16px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      gap: 15px;
      .header-back-arrow{
        display: flex;
        align-items: center;
        .MuiSvgIcon-root {
          fill: $color-black-text;
          width: 20px;
          height: 20px;
        }

      }
      span {
        font-size: 18px;
        font-family: $bold;
        color: $color-black-text;
      }
    }
    .content-info-kr {
      padding: 16px 16px 2px 16px;
      display: flex;
      margin-top: 16px;
      .progress-circular {
        margin: 0 16px 0 0;
      }
      .description-target-initial {
        display: flex;
        flex-direction: column;
        .description {
          height: 46px;
          overflow: hidden;
          p {
            text-overflow: ellipsis;
            color: $color-black-text;
            font-size: 16px;
            b {
              font-weight: $bold;
            }
          }
        }
        .values-description {
          margin: 15px 0 0 0;
          display: flex;
          color: $color-gray;
            span{
              font-size: 14px;
              font-weight: $bold;
              text-decoration: underline;
            }
          }
      }
    }
  }
}
