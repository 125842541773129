@import 'theme/variables', 'theme/colors';

.onboarding-header{
   display: flex;
   width: 100%;
   height: 100%;

   .onboarding-image{
      height: 40px;      
      background-color: $color-gray-soft;
      border-radius: 40px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 24px;
      margin-right: 24px;
      width: 40px;

      .image{
         height: inherit;
         width: inherit;
         object-fit: cover;
         border-radius: inherit;
      }
   }

   .onboarding-name{
      display: flex;
      align-items: center;
      p{
         font-size: $font-size-h1;
         font-weight: bold;
      }
   }
}