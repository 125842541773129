@import '~theme/variables', '~theme/colors';

.forgot-password-create-password-container {
  height: 100%;
  .title {
    font-size: 22px;
    font-family: $font-family-secondary;
    font-weight: $bold;
    margin-bottom: 60px;
  }
}