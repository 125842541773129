@import 'theme/variables';

.create-objectives-main-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;  
  position: absolute;

  .create-objectives-main-form-header {
    position: fixed;
    width: 100%;
    z-index: 100;
  }

  .create-objectives-main-form-content {
    width: 596px;
    margin: 0px auto;
    margin-top: 84px;

    .instructions {
      .instructions-title {
        font-size: 20px;
        font-weight: $semibold;
      }
    }
    
    .create-objectives-main-form-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      row-gap: 24px;
      .instructions {
        .instructions-title {
          font-size: 20px;
          font-weight: $semibold;
        }
    
        .instructions-description {
          font-size: 14px;
        }
      }
    }
  }

}

