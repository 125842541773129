@import 'theme/variables', 'theme/colors';

.generic-error-modal{
    .MuiDialog-paper{
        width: 464px;
        border-radius: 12px;
    }
    .error-modal-content{
        padding: 0px !important;
        .error-modal-container{
            padding-left: 24px;
            padding-right: 24px;
            .icon{
                margin-top: 47px;
                margin-bottom: 25px;
                .div-icon{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .MuiSvgIcon-root{
                        font-size: 67px !important;
                        fill: $color-error;
                    }
                }
                
            }
            .message{
                color: #666666;
                text-align: center;
                p{
                    font-size: 24px !important;
                    font-weight: $bold;
                }
            }
            .bottom{
                margin-bottom: 40px;
                hr{
                    color: $color-black;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    opacity: 12%;
                }
                .footer{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .MuiButton-root{
                        max-width: 100%;
                    }
                    .modal-button{
                        width: 345px;
                        background-color: $color-error;
                        font-size: $font-size-h3;
                        font-weight: $medium;
                    }
                }
            }
        }
    }
}