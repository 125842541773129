@import 'theme/variables', 'theme/colors';

.container-btn-tap-bottom{
  position: fixed;
  width: 100%;
  height: 64px;
  z-index: 100;
  bottom: 0;
  box-shadow: 0px -2px 10px $color-gray-shadow-color-secondary;
  background-color: $color-white;
  display: flex;
  align-items: center;
  padding: 0px 48px 0px 48px;
  .MuiButton-root{
    width: 100% !important;
    #txt-title{
      margin: 0 6px 0 1px ;
    }
  }
}