@import "~theme/variables", "~theme/colors";

.mobile-rate-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  .MuiSvgIcon-root{
    color: $color-warning;
  }
}
#span-text-review{  
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  // padding-right: $spacing;
}
.container-review-by{
  #by-rev{
    color: $color-gray-ae;
  }
}

.row-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container-table-mobile{
  .generic-data-table {
    .rdt_TableBody {
      .rdt_TableCell {
        overflow-x: hidden;
      }
    }
    & .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{
      padding: 0px;
    }
    & .mobile-column-title-review{
      padding-left: 16px;
    }
    & .mobile-rate-icon{
      margin: auto;
    }
    & .rdt_Pagination{
      & svg{
        right: -5px;
      }
    }
    & .table-error-message, .no-data-table-general{
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
