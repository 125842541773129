@import './colors';

$palette: (
  "default": (
    primary: $color-primary,
    secondary: $color-secondary,
    primary-soft: $color-primarySoft,
    primary-light: $color-primaryLight
  ),
  "blue": (
    primary: $color-primary-blue,
    secondary: $color-secondary-blue,
    primary-soft: $color-primarySoft-blue,
    primary-light: $color-primaryLight-blue
  ),
  "pink": (
    primary: $color-primary-pink,
    secondary: $color-secondary-pink,
    primary-soft: $color-primarySoft-pink,
    primary-light: $color-primaryLight-pink
  ),
  "purple": (
    primary: $color-primary-purple,
    secondary: $color-secondary-purple,
    primary-soft: $color-primarySoft-purple,
    primary-light: $color-primaryLight-purple
  ),
  "red": (
    primary: $color-primary-red,
    secondary: $color-secondary-red,
    primary-soft: $color-primarySoft-red,
    primary-light: $color-primaryLight-red
  ),
  "yellow": (
    primary: $color-primary-yellow,
    secondary: $color-secondary-yellow,
    primary-soft: $color-primarySoft-yellow,
    primary-light: $color-primaryLight-yellow
  ),
);

@mixin style($name) {
  @each $key, $map in $palette {
    @each $prop, $color in $map {
      @if $key == $name {
        --color-#{$prop}: #{$color};
      }
    }
  }
}

.palette-default {
  @include style("default");
}
.palette-blue {
  @include style("blue");
}
.palette-pink {
  @include style("pink");
}
.palette-purple {
  @include style("purple");
}
.palette-red {
  @include style("red");
}
.palette-yellow {
  @include style("yellow");
}
