.component-rategeneralreview {
  padding-top: 25px;
  
  .general-rate {
    margin-bottom: 40px;
  }
  .component-itemicontext {
    margin: 25px 0 40px;
  }

  .score-rate-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 48px;
  }
}