@import '~theme/variables', '~theme/colors';

.item-objective-row-to-select{
  height: 40px;
  display: flex;
  margin-top: 24px;
  div{
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .name{
    flex: 3;
  }
  .date{
    flex: 2;
    display: flex;
    justify-content: center;  
  }
  .progress{
    flex: 1;
    display: flex;
    align-items: flex-start;  
  }
}