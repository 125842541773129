@import 'theme/variables', 'theme/colors';

.tegdu-skeleton-loading {
  display: flex;
  border-radius: 4px;
  animation: skeleton-loading 0.55s linear infinite alternate;
  height: 100%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 93%);
  }
  100% {
    background-color: hsl(0, 0%, 97%);
  }
}

.row {
  min-height: 19px;
}

.form {
  min-height: 32px;
}

.chart {
  min-height: 100px;
}