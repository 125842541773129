@import '~theme/variables', '~theme/colors';

.resetPassword__container {
  position: relative;
  width: 100%;
  max-width: 464px;
  background: $color-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;


  .logo__container {
    display: flex;
    height: 108px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid (#00000012);

    img {
      width: 128px;
      height: 56px;
      max-width: 188px;
    }
  }

  .sucessIcon__container{
    margin-top: 25px;
  text-align: center;
  }

  .circleIcon{
    font-size: 90px;
    color: var(--color-primary);
  }

  .confirmSendEmail__container{
  margin-top: 25px;
  margin-bottom: 25px;
  color: $color-gray-dark;
  }

  .footer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

  }
}

.text-center{
  text-align: center;
}