@import "~theme/variables", "~theme/colors";

.container-table-card{
  margin-top: 32px;
}

.container-rate-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  .MuiSvgIcon-root{
    color: $color-warning;
  }
}
#span-text-review{
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.container-review-by{
  #by-rev{
    color: $color-gray-ae;
  }
}

.row-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.generic-data-table {
  .rdt_TableBody {
    .rdt_TableCell {
      overflow-x: hidden;
    }
  }
}
.container_btn_edit{
  width: 100%;
  display: flex;
  justify-content: center;
  .MuiButton-text{
    background-color: transparent;
    color: $color-gray;
  }
  .MuiButton-text:hover{
    background-color: lighten( $color-primary, 40%);
    color: $color-primary;
  }
}
