@import 'theme/variables', 'theme/colors';

.settings-container{
    .appbar-container{
      .MuiTab-textColorInherit.Mui-selected{
        color: var(--color-secondary);
      }
      .all-tabs{
          margin-top: 24px;
          #tags{
              min-width: 60px;
          }
          #notifications{
              min-width: 80px;
          }
          #timeframes{
              min-width: 60px;
          }
      }
    }
    #tags {
      min-width: 60px;
    }
    #notifications {
      min-width: 80px;
    }
    #timeframes {
      min-width: 60px;
  }
}

