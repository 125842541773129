@import 'theme/variables', 'theme/colors';

.organization-timeframes-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $spacing;
  min-height: 300px;

  .automatic-timeframes{
    margin-top: 23px;
    max-width: 700px;
    .section {
      border-top: 1px solid $color-gray-soft;
      padding-top: 23px;
    }
  }
}