@import 'theme/variables', 'theme/colors';

.modal-new-tag{
    .content{
        padding: 0px;
        padding-top: 0px !important;
       .new-tag-form{
           .title{
               margin-top: 24px;
               padding-bottom: 24px;
               border-bottom: 1px solid $color-gray-soft;
                text-align: center;
                color: $color-black-text;
                p{    
                    font-weight: $semibold;
                    font-size: $font-size-h1;
                }
           }
           .form-container{
               padding: 24px;
               .buttons{
                   margin-top: 24px;
                   display: flex;
                   justify-content: flex-end;
                   .cancel-btn{
                       margin-right: 5px;
                   }
               }
           }
       }
    }
}