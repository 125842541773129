@import 'theme/variables', 'theme/colors';

$common-margin: 24px;
.modal-objective-update {
  min-width: 632px;
  .content {
    padding: 0px;
    .corner-actions {
      display: flex;
      justify-content: flex-end;
    }
    .title-top {
      margin-bottom: $common-margin;
      .title-item {
        width: 65px;
        margin: auto;
        display: flex;
        margin-bottom: 15px;
        .logo {
          margin: auto;
        }
      }
      .title-text {
        text-align: center;
        color: $color-black-text;
        p {
          font-size: $font-size-h1;
          font-weight: $semibold;
        }
      }
    }
    .progress-info {
      border-top: 1px solid $color-gray-soft;
      .data-actions {
        margin-top: $common-margin;
        margin-bottom: $common-margin;
        .data {
          span {
            &.out {
              color: $color-error;
            }
          }
          p {
            font-size: 16px;
            font-weight: $semibold;
          }
        }
        .actions {
          display: flex;
          justify-content: flex-end;
          .add-progress {
            margin-right: 5px;
            .MuiButton-label {
              font-weight: $light;
            }
          }
        }
      }
      .bar {
        margin-bottom: $common-margin;
      }
    }
    #no-key-results {
      font-size: $font-size-h4;
      text-align: center;
      margin-top: 24px;
    }
    .key-result {
      margin-top: 24px;
      .key-detail {
        display: flex;
        margin-bottom: 16px;
        .progress-circle {
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
        }
        p {
          color: $color-black-text;
          span {
            font-weight: $semibold;
          }
        }
      }

      .key-info {
        margin-bottom: $common-margin * 2;
        p {
          margin-bottom: 4px;
        }
        .text-value {
          height: 32px;
          display: flex;
          p {
            align-self: center;
            font-weight: bold;
            color: var(--color-secondary);
          }
        }
      }
    }
  }
}
