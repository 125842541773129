@import '~theme/variables', '~theme/colors';

.circle-name{
  width: 100%;
  .circle-container{
    display: flex;
    flex-wrap: nowrap;
    .name{
      margin: auto 0px auto 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      .container-objective-view{
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        span:nth-child(1){
          font-size: 16px;
          color: $color-black-text;
        }
        span:nth-child(2){
          font-size: 14px;
          color: $color-gray-dark;
        }
      }
      .intern-name{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

}
