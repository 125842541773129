@import '~theme/colors', '~theme/variables', '~theme/animations';

#splash-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2147483660;

  &.animated {
    animation: splashbackground 1.4s infinite ease-in-out alternate;
  }
}