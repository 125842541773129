@import '~theme/colors', '~theme/variables';

.support-container{
  display: flex;
  flex-direction: row;
  .support-column-left{
    width: 640px;
    .support-searchbar{
      margin-bottom: 8px;
      margin-top: $spacing;
      max-width: 311px;
    }
    .support-faq-not-found {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: $spacing;
      min-height: 48px;
    }
  }
  .support-column-right{
    width: auto;
    .support-contact-container{
      height: 160px;
      margin-left: 64px;
      margin-top: 164px;
      max-width: 296px;
    }
    .information-container{
      display: flex;
      flex-direction: row;
      margin: 18px 0 $spacing;
      a {
        margin-left: 10px;
        text-decoration: none;
        color: $color-black-text;
        &:hover{
          opacity: 0.5;
        }
      }
    }
    .tour-button{
      margin-top: 18px;
    }
  }  
}

@media only screen and (max-width: 1000px){
  .support-container{
    flex-direction: column;
    .support-column-left{
      width: auto;
    }
    .support-column-right{
      .support-contact-container{
        margin-top: $spacing-2x;
        margin-left: $spacing;
      }
    }
  }
}