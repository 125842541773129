@import 'theme/variables', 'theme/colors';

.formik-text-area {
  .text-area:hover {
    border: 1px solid black ;  
  }
  .text-area {
    width: 100%;
    padding: 5px;
    resize: none;
    font-size: $font-size-p;
  }
  
  p {
    padding: 5px 0;
  }

  .error {
    border: 1px solid $color-error ;
  }

  .extra-info {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    .chars-info {
      .char-area {
        padding: 0px 2px;
        text-align: end;
        p {
          color: $color-gray;
          font-size: $font-size-s;
        }
      }
    }
  }
}
