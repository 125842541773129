@import '~theme/variables', '~theme/colors';

.begintour-container {
  .begintour-container-buttons {
    margin-top: $spacing;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  h2 {
    margin-bottom: 10px;
    color: $color-black-text;    
  }
  .step-content-text {
    font-size: 18px;
  }
}