@import "~theme/variables", "~theme/colors";

.container-start {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  .container-img-start {
    display: flex;
    flex: 1;
    .icon-rate {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
      }
      span {
        position: absolute;
        color: $color-white;
        font-family: $font-family-primary;
        font-weight: $bold;
        font-size: 25px;
        top: 26px;
      }
    }
  }
  .container-title {
    display: inline-flex;
    margin-top: 9px;
  }
}
