body,
input,
select,
textarea,
button {
  font-family: $font-family-primary, $font-family-secondary, Helvetica, sans-serif;
}

h1 {
  font-family: $font-family-secondary;
  font-size: $font-size-h1;
  font-weight: $bold;
}

h2 {
  font-size: $font-size-h2;
  font-weight: $semibold;
}

h3 {
  font-size: $font-size-h3;
  font-weight: $regular;
}

h4 {
  font-size: $font-size-h4;
  font-weight: $bold;
  text-transform: uppercase;
}

p {
  font-size: $font-size-p;
  font-weight: $regular;
}

label {
  font-size: $font-size-label;
  font-weight: $semibold;
}
