@import '~theme/colors';

.spinner {
  &.spinner-primary {
    color: var(--color-primary);
  }
  &.spinner-secondary {
    color: var(--color-secondary);
  }
  &.spinner-white {
    color: $color-white;
  }
  &.spinner-error {
    color: $color-error;
  }
  &.spinner-success {
    color: $color-success;
  }
  &.spinner-warning {
    color: $color-warning;
  }
  &.spinner-gray {
    color: $color-gray;
  }
}

button {
  .spinner {
    margin-right: 10px;
  }
}
