@import 'theme/variables', 'theme/colors', 'theme/fontface';

$transition-time: all 0s ease 0s;

.tegdu-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: none;
  font-family: $font-family-primary;
  .label-message {
    color: $color-black-text;
    font-size: 16px;
    margin-bottom: 4px;    
    font-weight: $semibold;
  }
  .tegdu-select-container {
    height: 32px;
    outline: none;
    &.disabled {
      .tegdu-select-prefix__control {
        background-color: $color-gray-soft-light;
        .tegdu-select-prefix__indicators {
          display: none;
        }
      }
    }

    &.viewMode {
      .tegdu-select-prefix__control {
        border-color: $color-white;
        background-color: $color-white;
        .tegdu-select-prefix__indicators {
          display: none;
        }
        .tegdu-select-prefix__single-value {
          color: $color-black-text;
        }
        .tegdu-select-prefix__multi-value {
          .tegdu-select-prefix__multi-value__remove {
            padding: 2px;
            svg {
              display: none;
            }
          }
        }
      }
    }
    .tegdu-select-prefix__control {
      border-color: $color-gray-ae;
      color: $color-black-text;
      font-size: 14px;
      min-height: 32px;
      transition: $transition-time;
      padding: 0;

      &:hover:not(.tegdu-select-prefix__control--is-focused) {
        border-color: $color-black;
        box-shadow: none !important;
      }

      .tegdu-select-prefix__value-container {
        transition: $transition-time;
        padding-left: 8px;
        padding-top: 0px;
        padding-bottom: 0px;
        outline: none;

        .tegdu-select-prefix__single-value {
          padding: 7px 0px 6px !important;
          margin-left: 0;
          width: 100%;
        }

        .tegdu-select-prefix__multi-value {
          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .tegdu-select-prefix__value-container--has-value {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .tegdu-select-prefix__indicators {
        .tegdu-select-prefix__indicator-separator {
          background-color: transparent;
        }
        .tegdu-select-prefix__dropdown-indicator {
          padding-top: 6px;
          padding-bottom: 6px;
        }
        .tegdu-select-prefix__clear-indicator {
          padding-top: 0;
          padding-bottom: 0;
        }
        .MuiSvgIcon-root {
          transition: $transition-time;
          color: $color-gray;
        }
      }
    }

    .tegdu-select-prefix__control--is-focused {
      border-color: var(--color-primary);
      box-shadow: none; // TODO: REVISAR ESTE PROP
    }

    .tegdu-select-prefix__menu {
      font-size: 14px;
      .tegdu-select-prefix__menu-list {
        .tegdu-select-prefix__option{
          text-overflow: ellipsis;
          overflow-x: hidden;
        }

        .tegdu-select-prefix__option--is-focused {
          background-color: $color-gray-soft;
        }
        .tegdu-select-prefix__option--is-selected {
          background-color: $color-gray-soft;
          color: $color-black;
        }
      }
    }

  }
}