@import 'theme/variables', 'theme/colors';

.objectives-actions-header {
  display: flex;
  height: 56px;
  padding: 16px 24px;
  width: 100%;
  background-color: $color-white;
  border-bottom: 1px solid $color-gray-soft;

  .actions-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .basic-info-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 16px;
        .MuiSvgIcon-root {
          color: $color-gray;
        }
      }    
      
      .title {
        font-size: 20px;
        font-weight: $semibold;
      }
    }

    .actions-content {
      display: flex;
      margin-left: 16px !important;
      .save-or-create{
        .root {
          color: $color-secondary;
          padding: 0;
        }
        .root.Mui-disabled {
          color: $color-gray;
        }
      }
    }
  }
}