@import '~theme/variables';

.component-form-individualrate {
  padding-top: 25px;

  .component-item-individualrate {
    margin-bottom: $spacing;
  }

  .score-rate-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 48px;
  }
}