@import 'theme/variables', 'theme/colors';

.archived-tags-container{
    .archived-tags-sub-container{
        .top-info{
            .archive-title{                
                color: var(--color-secondary);                
                margin-top: 31px;
            }
            .archive-description{
                color: $color-black-text;
                margin-top: 24px;
            }
        }
        .table-info{
            margin-top: 40px;
        }
    }
}