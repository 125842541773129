@import 'theme/variables', 'theme/colors', 'theme/fontface';

$form-width-side: 312px;

.cultural-attributes-form-component{
  display: flex;
  flex-direction: column;
  margin-top: $spacing;

  .culturalAttributes-form-inputs {
    display: flex;
    flex-direction: column;
    width: 640px;
    row-gap: 28px;
    margin-top: $spacing;    
    border-bottom: 1px solid $color-gray-soft;
    .type-name {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .attribute-type {
        display: flex;
        flex-direction: column;
        width: $form-width-side;
        .label-type {
          font-size: 16px;
          font-weight: $semibold;
          margin-bottom: 4px;
        }
      }

      .attribute-name {
        width: $form-width-side;
      }
    }

    .valid-until {
      display: flex;
      width: $form-width-side;
      .tegdu-date-picker-container {
        width: 100%;
      }
    }

    .owner-coach {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .owner {
        width: $form-width-side;
      }

      .coach {
        width: $form-width-side;
      }
    }

    .archive {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 28px;
    }

    .delete-icon {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 28px;
      .MuiSvgIcon-root {
        color: $color-error; 
        cursor: pointer;
      }
    }


  }
  .add-attribute {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
    width: 640px;
  }

  .see-archived {
    display: flex;
    margin-top: 28px;
    width: 640px;    
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    color: $color-gray;
  }
}