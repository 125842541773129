@import 'theme/variables', 'theme/colors';

.modal-unarchive-tag{
    .MuiDialog-paper{
        border-radius: 12px;
    }
    .content{
        padding: 0px !important;
        .content-container{
            #label{
                font-size: $font-size-h4;
            }
            #value{
                margin-top: 12px;
            }

            .title{
                margin-top: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid $color-gray-soft;
                text-align: center;
                color: $color-black-text;
                p{    
                    font-weight: $semibold;
                    font-size: $font-size-h1;
                }
            }

            .tag-name, .objectives, .buttons{
                margin-bottom: 24px;
                margin-top: 24px;
                padding-left: 24px;
                padding-right: 24px;                               
            }

            .objectives{
                .objectives-row{
                    border-bottom: 1px solid $color-gray-soft;
                    border-top: 1px solid $color-gray-soft;                    
                    height: 100px;
                    margin-top: 12px;                    
                }

                .overflow{
                    overflow-y: scroll;
                }

                .overflow-none{
                    overflow-y: hidden;
                }

                .objectives-empty{
                    border-top: 1px solid $color-gray-soft;
                    margin-top: 12px;     
                    padding-top: 48px;
                    text-align: center;
                }
            }

            .buttons{
                display: flex;
                justify-content: flex-end;
                .cancel-btn{
                    margin-right: 5px;
                }
            }
        }
    }
}