@import '~theme/variables', '~theme/colors';

.onboarding-set-password {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  .password-title {
    text-align: left;
    font-size: 14px;
  }

  .password-rules {
    display: flex;
    width: 100%;
    .left, .right {
      width: 50%;
    }
  }

  .password-form {
    text-align: left;
    .button-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}