@import 'theme/variables', 'theme/colors';

.generic-error-component {
    margin-top: 60px;
    p {        
        text-align: center;
    }
    .try-btn {
      display: flex;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }