@import 'theme/variables', 'theme/colors';

.members-filters{
    margin-bottom: 24px;
    .members-filter-container{
        .members-search-bar{
            display: flex;
            .members-text-field{
                align-self: flex-end;
                min-width: 311px !important;
            }
        }

        .members-multi-dropdowns{
            display: inline-flex;
            .drop-filters{
                width: 200px;
                //margin-right: 24px;
            }
            .MuiFormLabel-root{
                font-size: 14px;
                color: $color-black-text;
            }
        }

        .members-button{
            display: flex;
            justify-content: flex-end;
            .search-button{
                align-self: flex-end;
            }
        }
    }
}

/* Responsive design */
@media only screen and (max-width: 835px){
    .members-filters{
        .members-filter-container{
            .members-button{
                margin-top: 20px;
                display: flex;
                justify-content: center;
            }
        }
    }
}