@import '~theme/variables', '~theme/colors';
.container-card{
  width: 100%;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  box-shadow: 0px 3px 11px 5px $color-gray-shadow-color;
  .title{
    display: flex;
    height: 40px;
    padding-left: 16px;
    align-items: flex-end;
    margin: 5px 0px 10px 0px;
    span{
      font-size: 18px;
      font-family: $font-family-primary;
      color: $color-black-text;
      font-weight: $semibold;
    }
  }
}