@import "theme/variables", "theme/colors";

.notifications-component{
  margin-right: 24px;
  position: relative;
  & .icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    &:hover{
      cursor: pointer;
      background-color: $color-gray-hover-light;
    }

    & .notification-icon{
      color: $color-gray;
      position: relative;
      margin-right: 0px;
      & .red-dot{
        background-color: $color-error;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        position: absolute;
        top: 1px;
        right: 1px;
        transition: all 0.2s;
      }
    }
  }
  & .is-active{
    background-color: $color-gray-soft;
  }
  & .notifications-wrapper{
    position: absolute;
    right: 0px;
    .notifications-empty, .notifications-loading{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 15px;
    }
  } 
}

.MuiDialog-root.notifications-menu, .MuiDialog-root.empty-notifications-menu, .MuiDialog-root.error-notifications-menu{
  .MuiDialog-container.MuiDialog-scrollPaper{
    display: block;
    ::-webkit-scrollbar{
      border-radius: 15px;
      background-color: $color-white;
      width: 10px;  
    }
    ::-webkit-scrollbar-thumb{
      background-color: $color-gray-soft-2;
      border-radius: 10px;
    }
  }
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.MuiPaper-elevation24.MuiPaper-rounded{
    position: absolute;
    top:10px;
    right: 275px;
    width: 350px;
    height: calc(88px * 7);
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .MuiPaper-elevation24{
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.05), 
      0px 24px 38px 3px rgba(0, 0, 0,0.05), 
      0px 9px 46px 8px rgba(0, 0, 0 , 0.05);
  }
}
.MuiDialog-root.empty-notifications-menu, .MuiDialog-root.error-notifications-menu{
  & .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.MuiPaper-elevation24.MuiPaper-rounded{
    justify-content: center;
    height: 320px;
    
    & .notifications-error{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 15px;
    }
  }
}
@media only screen and (min-width: 415px) and (max-width: 992px){
  .MuiDialog-root.notifications-menu, .MuiDialog-root.empty-notifications-menu, .MuiDialog-root.error-notifications-menu{
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.MuiPaper-elevation24.MuiPaper-rounded{
      position: absolute;
      top: 10px;
      right: 37px;
      width: 350px;
      height: calc(88px * 7);
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }
  }
}