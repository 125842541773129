@import "~theme/variables", "~theme/colors";
.generic-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  margin-top: 5px;
  & .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{
    padding: 0px;
  }
  .name-okr-mobile {
    overflow: hidden;
    height: 20px;
    width: 140px;
    display: block;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
}