@import "theme/variables", "theme/colors";

.component-inlinekeyresult {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 21px;
  h4 .inlinekeyresult-name {
    font-weight: $regular;
    text-transform: none;
  }
}