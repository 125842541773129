@import '~theme/variables', '~theme/colors';

.column-title {
  text-transform: uppercase;
  &.column-owner {
    margin-left: 6px;
  }
}

.row-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.generic-data-table {
  .rdt_TableBody {
    .rdt_TableCell {
      overflow-x: hidden;
    }
    .dot-red {
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $color-error;
      left: 7px;
    }
    .obj-ownerletters {
      margin-left: 6px;
    }
  }
}