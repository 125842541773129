@import 'theme/variables', 'theme/colors';

.tegdu-text-area {
  display: flex;
  flex-direction: column;
  font-family: $font-family-primary;
  width: 100%;

  .label-message {
    color: $color-black-text;
    font-size: 16px;
    margin-bottom: 4px;    
    font-weight: $semibold;
  }

  .view-mode-value {
    color: $color-black-text;
    font-size: 14px;
    padding: 7px 8px 6px;
  }
  
  .text-area-style {
    display: flex;
    padding: 7px 8px 6px;
    resize: none;
    font-size: 14px;
    font-family: inherit;
    outline: none;
    border-radius: 4px;
    border: 1px solid $color-gray-ae;
  }

  .text-area-style:hover {
    border: 1px solid black ;  
  }

  .error {
    border: 1px solid $color-error ;
  }

  .complementary-info {
    display: flex;
    flex-direction: row;
    height: 16px;
    margin-top: 4px;
    font-size: 12px;
    .error-message {
      color: $color-error;
    }

    .max-length{
      margin-left: auto;
      color: $color-gray;
    }
    
  }
}
