@import 'theme/variables', 'theme/colors';

.container-review-general{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .container-title-subtitle{
    span{
      margin: 20px 0px 20px 0px;
      font-size: 20px;
      color: $color-black-text;
    }
  }
  .container-filters{
    margin: 26px 0px 0px 0px;
  }
}