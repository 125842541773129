@import '~theme/variables', "~theme/colors";

.containerScoreObjectiveDetailTitle{
  width: 78%;
  display: flex;
  flex-direction: row;
  .containerStart{
    position: relative;
    #icon-start{
      width: 60px;
      height: 60px; 
      color: $color-warning;
    }
    .scoreReviewAsigned{
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      #score{
        color: $color-black-text;
        font-size: 14px;
        font-family: $font-family-primary;
        font-weight: $bold;
      }
    }
  }
  .containerTitleDate{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2px;
    width: 100%;
    #txtTitle{
      color: $color-black-text;
      font-size: 16px;
      font-family: $font-family-primary;
      font-weight: $semibold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
    }
    #txtDate{
      color: $color-gray;
      font-size: 12px;
      font-family: $font-family-primary;
      font-weight: $regular;
      margin-top: 2px;
    }
  }
}