@import '~theme/variables', '~theme/colors';

.container-login-forms{
  display: flex;
  flex: 1;
  .form{
    display: flex;
    flex: 1;
    flex-direction: column;
    .inputs-section{
      display: flex;
      flex: 1;
      flex-direction: column;
      .MuiOutlinedInput-root{
        height: 48px;
      }
    }
    .button-forgot-pass{
      flex: 1;
      display: flex;
      flex-direction: column;
      .forgot-password{
        display: flex;
        height: 50px;
        justify-content: flex-end;
        text-decoration: underline;
        color: $color-gray ;
      }
      .btn-login{
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .MuiButton-text{
          width: 176px;
          height: 40px;
        }
        
      }
    }
  }
}