@import 'theme/variables', 'theme/colors';

.add-members-form{
  display: flex;
  flex-direction: column;
  .upload-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-decoration: underline;
    span {
      cursor: pointer;
      color: $color-gray;
    }
    .MuiSvgIcon-root {
      color: $color-gray;
      height: 18px;
    }
  }

  .formik-creatable-dropdown{
    .creatable__select__component{
      height: 64px;
      .creatable__drop__container{
        height: inherit;
        .creatable__drop__control{
          height: inherit;
          .creatable__drop__indicators{
            display: none;
          }
          .creatable__drop__value-container{
            height: 100%;
            overflow-y: scroll;
          }
        }
      }
    }
  }
  .role-input{
    margin-top: 22px;
  }
  .button{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 36px;
    .MuiButtonBase-root {
      .MuiButton-label {
        display: flex;
        justify-content: center;
        .spinner-button {
          display: flex;
          width: 100%;
          justify-content: center;
          .spinner {
            margin: 0px;
          }
        }
      }
    }
  }
}