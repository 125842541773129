@import 'theme/variables', 'theme/colors';

.statement-form {
  .description-container {
    margin: $spacing 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-div {
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 428px){ 
        justify-content: center;
       }
      button:last-of-type {
        margin-left: 12px;
      }
    }
  }

  form {
    max-width: 640px;

    .statement {
      .statement-fields {
        padding-bottom: 12px;

        &:not(:last-of-type) {
          border-bottom: 1px solid $color-gray-soft;
          margin-bottom: 24px;
        }
      }
    }

    .footer-actions {
      margin-bottom: 12px;
    }
  }

  // .first-row-container {
  //   min-height: 106px;
  // }
  // .description-row-container {
  //   min-height: 156px;
  // }
}
