@import 'theme/variables', 'theme/colors';

.skills-aptitudes-modal{
    .MuiDialog-container{
        .MuiPaper-root{
            align-self: center;
            border-radius: 12px;
            min-height: 500px;
            min-width: 464px;
        }
        .form-title-container{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 0;
            border-bottom: 2px solid rgba($color: #000000, $alpha: 0.12);
        }
        .skills-aptitudes-main-form{
            padding: 24px;
        }
        .round-buttons-container{
            margin-top: 10px;
        }
        .description-container{
            margin-top: 24px;
            height: 100px;
        }
        .buttons-container{
            display: flex;
            justify-content: flex-end;
            padding-right: 24px;
            padding-top: 14px;
            
            .buttons-container Button {
                width: 111px;
            }
        }
    }
}