@import 'theme/variables', 'theme/colors';

.objective_role_progress_container {
  margin: 0 $spacing;
  max-width: 90px;
  .progress_info {
    display: flex;
    justify-content: center;
    margin-bottom: 4px;
    .progress {
      color: var(--color-secondary);
      margin-right: 8px;
      font-weight: $bold;
    }
    .role {
      font-weight: $regular;
    }
  }
  .component-progressbar {
    margin: 0 auto;
  }
}
