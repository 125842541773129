@import '~theme/variables', '~theme/colors';

.login__container {
  min-height: 100vh;
  background-repeat: repeat;
  background-size: cover;  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px $spacing;
}
