
.tegdu-datepicker-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  .datepicker-months {
    margin-bottom: 15px;
    width: 100px;
  }

  .datepicker-years {
    width: 80px;
  }
}