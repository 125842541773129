@import "~theme/variables", "~theme/colors";

.container-spinner{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.container-dash {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
  gap: 24px;
  padding: 0px 16px 100px 16px;
  .card-active-objectives{
    padding: 0px 35px 16px 35px;
    display: flex;
    flex-direction: column;
    height: 300px;
    .container-rating{
      flex: 6;
      display: flex;
      align-items: center;
      column-gap: 65px;
      flex-wrap: wrap;
      span:nth-child(1){
        font-size: 14px;
      }
      .container-start-rating{
        display: flex;
        flex: 1;
      }
    }
  }
  .container-see-all{
    display: flex;
    justify-content: center;
    background-color: $color-white;
    align-items: center;
    height: 80px;
    span{
      color: $color-primary;
      text-decoration: underline;
    }
  }
  .container-see-all-close{
    display: flex;
    justify-content: center;
    background-color: $color-white;
    align-items: center;
    margin-bottom: 15px;
    span{
      color: $color-primary;
      text-decoration: underline;
    }
  }
  .card-container-recently{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    overflow: hidden;
    max-height: 165px;
    padding: 0px 16px 16px 16px;

  }
  .card-container-recently-all{
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    padding: 0px 16px 16px 16px;
  }
  .carousel-container{
    display: flex;
    height: 300px;
    div{
      width: 100%;
      background-color: antiquewhite;
      height: 100%;
    }
  }
  .card-carousel{
    padding: 0px 16px 16px 16px;
  }
}
