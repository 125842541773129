@import 'theme/variables', 'theme/colors';


.add-members-from-file {
  display: flex;
  flex-direction: column;
  .back-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    .MuiSvgIcon-root {
      cursor: pointer;
      color: $color-black-text;
    }
  }

  .upload-from-file-titles {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 18px;
    #title {
      color: $color-black-text;
      font-size: 24px;
      font-weight: $semibold;
    }
    #text {
      font-size: 16px;
      padding: 0px 50px;
    }
  }
  .upload-from-file {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 36px;
    width: 100%;
  }
}