@import '~theme/variables';

.modal-rateobjective {
  .MuiDialogContent-root {
    padding: 32px 88px 32px;
  }
  p {
    &.modal-rateobjective-title {
      font-size: $font-size-h2;
      font-weight: $medium;
      text-align: center;
      margin-bottom: 26px;
    }
    &.modal-rateobjective-subtitle {
      font-size: $font-size-h4;
      text-align: center;
      margin-bottom: 34px;
    }
  }
}