@import 'theme/variables', 'theme/colors', 'theme/fontface';

.tegdu-text-input-component {
  display: flex;
  flex-direction: column;  
  width: 100%;
  font-family: $font-family-primary;

  .label-message {
    font-size: 16px;
    color: $color-black-text;
    font-weight: $semibold;
    margin-bottom: 4px;
  }

  .text-input {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    width: 100%;
    color: $color-black-text;
    .text-input-edit-mode {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 8px 6px;
    }

    .metric-style-light{
      color: $color-gray;
    }
    .metric-style-strong{
        color: $color-black;
    }
  }

  .complementary-info {
    display: flex;
    flex-direction: row;
    height: 16px;
    margin-top: 4px;
    font-size: 12px;
    .error-message {
      color: $color-error;
    }

    .max-length{
      margin-left: auto;
      color: $color-gray;
    }
    
  }

  .MuiFormControl-root{
    height: inherit;
    width: inherit;
    .Mui-focused .MuiOutlinedInput-notchedOutline{
      border-width: 1px;
    }
    .MuiOutlinedInput-input{
      padding: 7px 8px 6px;
    }
  }

  .MuiInputBase-root{
    height: inherit;
    font-family: 'Nunito';
    .MuiInputBase-input{
      font-size: 14px;
    }
  }

  // Error styles
  &.with-error {
    .MuiFormControl-root{
      height: inherit;
      width: inherit;
      .MuiOutlinedInput-notchedOutline{
        border-color: $color-error;
      }

      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: $color-error;
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
        border-color: $color-error;
      }
    }
    .MuiInputBase-root {
      height: inherit;
      font-family: 'Nunito';
      .MuiInputBase-input {
        font-size: 14px;
        color: $color-error;
      }      
    }
  }
}
