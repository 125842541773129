@import 'theme/variables', 'theme/colors';

.user-dashboard-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  // min-height: 550px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  .horizontal_separator {
    width: 100%;
    height: 1px;
    background-color: $color-gray-soft;
  }
  .user_info_content {
    display: flex;
    padding: 30px 24px;
    .avatar_container {
      .image-content {
        border-radius: 50%;
        height: 100px;
        width: 100px;
        background-color: $color-gray-soft;
      }
    }
    .user_info {
      padding-left: 24px;
      h2 {
        margin-bottom: 8px;
        color:$color-black-text;
      }
      p {
        font-size: $font-size-tag;
        color: var(--color-secondary);
      }
    }
  }
  .objectives_general_info {
    padding: $spacing;
    & > h4 {
      text-align: center;
      margin-bottom: 12px;
      font-weight: $regular;
      text-transform: none;
    }
    .objectives_counts {
      display: flex;
      justify-content: center;
      align-items: center;
      .separator {
        width: 1px;
        height: 40px;
        background-color: $color-black;
        opacity: 0.12;
      }
    }
  }
  .noobjectives {
    padding: $spacing-2x $spacing;
    color: $color-gray;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  }
  .footer {
    width: 100%;
    padding: 9px;
    display: flex;
    justify-content: center;
    background-color: $color-gray-hover-light;
    cursor: pointer;
    transition: all 0.2s ease 0s;

    &:hover {
      background-color: darken($color-gray-hover-light, 4%);
    }

    p {
      text-transform: uppercase;
      color: var(--color-secondary);
      font-weight: $semibold;
    }
  }
}

/* Reponsive design */
@media only screen and (max-width: 428px){
  .user-dashboard-card{
    .objectives_general_info{
      padding: 0px;
      margin-top: $spacing;
      margin-bottom: $spacing;

      .objectives_counts{
        display: flex;
        flex-direction: column;
        .objective_role_progress_container{
          margin-bottom: 12px;
        }        
        .separator{
          display: none;
        }
      }
    }
  }
}