@import 'theme/variables', 'theme/colors';

button.objective-type-button-pwa {
  .MuiButton-label {
    font-size: 14px;
    font-weight: $regular;
  }

  border-radius: 25px !important;
  margin-right: 14px !important;
  &.selected {
    background: var(--color-primary) !important;
    color:$color-white !important;
  }
}
