@import 'theme/variables', 'theme/colors', 'theme/fontface';

.timeframes-form-component{
  display: flex;
  flex-direction: column;
  width: 100%;

  .organizations-forms-header {
    margin-bottom: $spacing;
  }

  .timeframes-form-inputs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 16px;
    width: 100%;
    .input-form {
      width: 280px;
      margin-right: 40px;
    }

    .separator {
      display: flex;
      align-items: center;
      width: 24px;
      height: 32px;
      hr {
        width: 10px;
        margin: auto;
        color: $color-gray-soft;
      }
    }

    .input-startdate, .input-endDate {
      width: 112px;
    }

    .delete-icon {
      cursor: pointer;
      margin-left: 30px;
      .MuiSvgIcon-root {
        color: $color-error;
      }
    }
  }
}