@import "~theme/variables", "~theme/colors";

.container-table-card{
  margin-top: 32px;

}
.column-title {
  text-transform: uppercase;
}

.row-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.generic-data-table {
  .rdt_TableBody {
    .rdt_TableCell {
      overflow-x: hidden;
    }
  }
}

.container_btn_edit{
  width: 100%;
  display: flex;
  justify-content: center;
  .MuiButton-text{
    background-color: transparent;
    color: $color-gray;
  }
  .MuiButton-text:hover{
    background-color: lighten( $color-primary, 40%);
    color: $color-primary;
  }
}
