@import "~theme/variables", "~theme/colors";

.container-details-mobile {
  display: flex;
  flex-direction: column;
  @keyframes animationHeader {
    from {
      color: $color-white;
      box-shadow:0px 0px 0px $color-gray-f1
    }
    to {
      box-shadow: 0px 2px 10px $color-gray-f1;
      color: $color-black-text;
    }
  }
  & .screen-loader{
    min-height: 100vh;
  }
  .back-btn-copy-id-container-mobile {
    padding: 0 16px 0 16px;
    position: sticky;
    overflow: hidden;
    white-space: nowrap;
    top: 0;
    display: flex;
    z-index: 999;
    background-color: $color-white;
    height: 56px;
    flex-direction: row;
    align-items: center;
    .container-title-header{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 10;
      span{
        font-size: 18px;
        font-weight: $semibold;
      }
    }
    .MuiSvgIcon-root{
      font-size: 20px
    }
    .icon-arrow {
      flex: 1;
      display: flex;
      margin-right: 12px;
    }
    .icon-more {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
  .back-btn-copy-id-container-mobile-top {
    @extend .back-btn-copy-id-container-mobile;
    box-shadow: 0px 2px 10px $color-gray-f1;
    animation-name: animationHeader;
    animation-duration: 1s;
  }
  .container-title-progress{
    display: flex;
    padding: 5px 16px 0 16px;
    gap: 10px;
    .name-okr{
      display: flex;
      justify-content: center;
      span{
        margin-top: 5px;
        font-size: 20px;
        font-weight: $semibold;
        font-family: $font-family-primary;
      }
    }
  }
  .container-date-copy{
    padding: 5px 16px 0 16px;
    display: flex;
    flex-direction: row;
    .date{
      flex: 3;
      display: flex;
    }
    .copy-btn{
      display: flex;
      flex: 1;
      justify-content: flex-end;
      #icon-copy{
        font-size: 16px;
        margin-left: 5px;
      }
      span{
        font-size: 14px;
        font-family: $font-family-primary;
      }

    }
  }
  .container-tags{
    padding: 18px 16px 0 16px;
    display: flex;
    flex-direction: row;
  }
  .container-description-pilar{
    display: flex;
    flex-direction: row;
    padding: 10px 16px 0 16px;
    gap: 10px;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    span{
      font-size: 14px;
    }
  }
  .container-roles{
    padding: 20px 16px 0 16px;
    .title-role{
      margin-bottom: 18px;
      border-bottom: solid 2px $color-gray-icons-color ;
      span{
        font-size: 16px;
        color: $color-gray-dark;
        font-size: 14px;
        font-family: $font-family-primary;
      }
   
    }
    .container-roles-content{
      padding: 0 16px 0 16px;
      .circle-name .circle-container .name{
        margin: 0;
      }
      .container-objective-view{
        span{
          font-weight: $bold;
        }
        span:nth-child(2){
          font-size: 12px;
          color: $color-gray-dark;
  
        }
      }
    }
  }
  .container-teams{
    padding: 10px 16px 0 16px;
    .title-teams{
      margin-top: 18px;
      margin-bottom: 10px;
      border-bottom: solid 2px $color-gray-icons-color ;
      span{
        font-size: 16px;
        color: $color-gray-dark;
        font-size: 14px;
        font-family: $font-family-primary;
      }
    }
    .container-tags-teams{
      display: flex;
    }
  }
  .container-kr{
    padding: 49px 16px 30px 16px;
    .title-kr{
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;
      border-bottom: solid 2px $color-gray-icons-color ;
      span{
        font-size: 16px;
        color: $color-gray-dark;
        font-size: 14px;
        font-family: $font-family-primary;
      }
      .container-text-see-all{
        span{
          text-decoration: underline;
          font-size: 14px;
        }
      }
    }
    .key-detail{
      display: flex;
      gap: 10px;
      margin-bottom: 24px;
    }
  }
  .container-logs{
    width: 100%;
    height: 75px;
    margin-bottom: 200px;
    padding: 19px 16px 200px 16px;
    .title-logs{
      border-bottom: solid 2px $color-gray-icons-color ;
      span{
        font-size: 16px;
        color: $color-gray-dark;
        font-size: 14px;
        font-family: $font-family-primary;
      }
    }
    .container-log{
      .target-log{
        margin-right: 15px;
        font-size: 14px;
      }
    }
    & .objective-log-wrapper{
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}
