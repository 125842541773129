@import '~theme/variables', '~theme/colors';

.buttons-container{
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	.button-selected{
		background: $color-gray-dark;
		border-radius: 16px;
		cursor: auto;
		font-size: 14px;
		margin-right: 16px;
		span {
			color: $color-white;
			font-weight: normal;
		}
	}
	.button-selected:hover {
    background: $color-gray-dark;
  }
	.button-not-selected{
		background: $color-gray-f1;
		border-radius: 16px;
		font-size: 14px;
		margin-right: 16px;
		span {
			color: $color-black-text;
			font-weight: normal;
		}
	}
	.button-not-selected:hover{
		background: var(--color-primary-soft);
    span {
      color: var(--color-primary);
      font-weight: normal;
    }
	}
}