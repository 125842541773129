@import '~theme/variables', '~theme/colors';

.objectives_filters {
  width: 100%;
  display: flex;
  margin-bottom: $spacing;
  .many-selected {
    background: $color-gray-dark;
    border-radius: 16px;
    cursor: auto;
    font-size: 14px;
    margin-right: 16px;
    span {
      color: $color-white;
      font-weight: normal;
    }
  }

  .many-selected:hover {
    background: $color-gray-dark;
  }

  .many-selected:disabled {
    background: $color-gray-dark;
  }

  .many-no-selected {
    background: $color-gray-f1;
    border-radius: 16px;
    font-size: 14px;
    margin-right: 16px;
    span {
      color: $color-black-text;
      font-weight: normal;
    }
  }

  .many-no-selected:hover {
    background: var(--color-primary-soft);
    span {
      color: var(--color-primary);
      font-weight: normal;
    }
  }

  .objective_buttons,
  .objective_dropdowns {
    width: 50%;
  }

  .objective_dropdowns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;
    .status_dropdown {
      width: 180px;
    }
    .line-sepatator-report{
      width: 2px;
      height: 25px;
      background-color:$color-gray-dark-2;
      margin-left:10px;
    }
    .report_dropdown{
      margin-left:0px;
    }
    .line_separator{
      width: 2px;
      height: 37px;
      background-color:$color-gray-dark-2;
      margin: 0px 30px 0px 30px;
    }
  }

  .hidden {
    display: none;
  }
}
