@import 'theme/variables', 'theme/colors';

.status-component{
    width: 110px; 
    .status-container{
        .status-icon{
            display: flex;
            justify-content: left;
            margin: auto 0px;
            &.Active{
                .MuiSvgIcon-root{
                    fill: var(--color-primary);
                }
            }
            &.Suspended{
                .MuiSvgIcon-root{
                    fill: $color-error;
                }
            }
        }
        .status-text{
            text-align: left;
        }
        .table{
            .MuiSvgIcon-root{
                width: 18px !important;
                height: 18px !important;
            }
        }
        .component{
            font-weight: $medium;
            font-size: 20px;
            
            .MuiSvgIcon-root{
                width: 20px !important;
                height: 20px !important;
            }
            &.Suspended{
               color: $color-error;
            }
            &.Active{
                color: var(--color-primary);
            }
        }

        
    }
}