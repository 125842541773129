@import '~theme/variables', '~theme/colors';

.invitations-onboarding-container {
  width: 464px;
  height: 565px;
  background-color: $color-white;  
  border-radius: 12px;
  margin: auto;
  box-shadow: 0px 3px 6px #00000029;
  
  .content {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 27px 24px;
    text-align: center;
    .logo-tegdu {
      display: flex;
      width: 100%;
      justify-content: center;
      img {
        width: 89;
        height: 39px;
      }
    }
    #company-title {
      margin-top: 27px;
      font-size: 24px;
      font-weight: $bold;
    }
    #domain-title {
      margin-top: 14px;
      font-size: 14px;
      font-weight: $regular;
      color: $color-gray-dark;
    }
    .line {
      margin: 24px 0px;
      border-bottom: 1px solid $color-black;
      opacity: 0.12;
    }

    .container-title {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 600;
    }

    .containers-area {
      display: flex;
      height: 100%;
    }
  }
  .loading {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}