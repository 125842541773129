@import "~theme/variables", "~theme/colors";

.component-rategeneralreview {
  padding-top: 25px;
  
  & .general-mobile-rate {
    margin-bottom: 40px;
    & .MuiRating-label .MuiRating-icon .MuiSvgIcon-root{
      height: 65px !important;
      width: 65px !important;
    }
  }
  & .component-itemicontext {
    margin: 25px 0 40px;
  }

  & .container-btn-tap-bottom.general-review {
    display: flex;
    gap: 16px;
    margin-top: 48px;
    left: 0;
    padding: 0 20px;
  }
  & .general-review-info-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;

    & .objective-icon-text {
      display: flex;
      align-items: center;
      flex-direction: row;      
      width: 100%;
      margin-bottom: 18px;
      font-weight: $semibold;
      .MuiSvgIcon-root {
        color: $color-gray-icons-color;
      }
      & span {
        margin-left: 19px;
        color: $color-black-text;
      }
    }
  }
  & form{
    margin: 40px 0 20px;
    width: 100%;

  }
}