@import '~theme/variables', '~theme/colors';

.component-circlewithname {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  .name {
    font-weight: $bold;
    font-family: $font-family-secondary;
    font-size: $font-size-label;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.xs {
      font-size: $font-size-p;
      font-weight: $regular;
      font-family: $font-family-primary;
    }
  }
}
