@import '~theme/variables', '~theme/colors';

.objectives-search-review-container{
  .input-search-area{}
  .container-items-objective-searched{
    
    .containerRecent{
      margin-top: 24px;
      #title-recent{
        font-size: 14px;
        color: $color-gray-light ;
      }
    }
  }

}