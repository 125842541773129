@import '~theme/variables', '~theme/colors';

.card-newpassword {
  width: 100%;
  max-width: 464px;
  background: $color-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;

  .card-newpassword-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px $spacing 0;
    img {
      cursor: pointer;
    }
    hr {
      width: 100%;
      border-bottom: 1px solid $color-black;
      opacity: 0.12;
      margin-top: 40px;
    }
  }
  .card-newpassword-body {
    padding: 40px $spacing $spacing;
  }
}
