@import '~theme/variables', '~theme/colors';

.form-organizationinfo {
  .description-container {
    margin: $spacing 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-div {
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 428px){ 
        justify-content: center;
       }
      button:last-of-type {
        margin-left: 12px;
      }
    }
  }
  .container-image-organization{
    display: flex;
    margin-bottom: 30px;
    .container-info-image{
      display: flex;
      justify-content: center;
      flex-direction: column;
      span:nth-child(1){
        font-size: 16px;
        color: $color-black-text;
        margin-bottom: 2px;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      span:nth-child(2){
        font-size: 12px;
        color: $color-gray;
        width: 128px;
      }
    }
    .container-upload-image{
      height: 104px;
      width: 104px;
      display: flex;
      margin-left: 38px;
      background-color: $color-gray-f1;
      border-radius: 5px;
      .MuiSvgIcon-root{
        cursor: pointer;
        font-size: 30px;
        width: 100%;
        height: 100%;
        padding: 35px;
        color: $color-gray;
      }
      #error-text-pixels{
        color: #FFD460;
        font-size: 10px;
        display: flex;
        bottom: 46px;
        width: 100px;
      }
      #img-to-show{
        width: 104px;
        height: 104px;
      }
      #input[type="file"]{
        display: none;
     }

    }
  }
}
