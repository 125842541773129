@import '~theme/variables', '~theme/colors';

.review-again-individual-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 54px;
    width: 100%;
    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 19px;
      .MuiSvgIcon-root {
        color: $color-gray-icons-color;
      }
      span {
        font-size: 16px;
        margin-left: 19px;
      }

      .sub-row {
        display: flex;
        flex-direction: column;
        #role {
          color: $color-gray-icons-color;

        }
      }
    }
  }

  .rate-area {
    display: flex;
    justify-content: center;
  }

  .button-area {
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%;
  }
}