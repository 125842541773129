@import "theme/variables", "theme/colors";

.pending-table{
  .card-container{
    position: relative;
    header {
      background-color: $color-gray-f7;
      min-height: 70px;
    }
    .rdt_TableBody .rdt_TableRow .rdt_TableCell{
      padding: 0px 16px;
      margin: 0px;
    }
    .buttons-wrapper{
      position: absolute;
      top: 17px;
      right: 20px;
      display: flex;
      align-items: center;
      column-gap: 24px;
      .MuiButton-outlined{
        border: unset;
        color: $color-gray;
        font-size: $font-size-input;
        text-transform: initial;
        &:disabled{
          pointer-events: none;
          opacity: 0.4;

        }
      }
    }
  }
}