@import 'theme/variables', 'theme/colors';

.formik-text-input {
  position: relative;

  p.view-mode {
    padding: 7px 0 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .extra-info {
    position: absolute;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    .chars-info {
      .char-area {
        padding: 0px 2px;
        text-align: end;
        p {
          color: $color-gray;
          font-size: $font-size-s;
        }
      }
    }
  }

  .iconContainer {
    display: flex;
    color: $color-black;
    opacity: 0.4;
    transition: all 0.3s ease 0s;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 10px;
  }

  .MuiOutlinedInput-inputAdornedStart{
    padding-left: 5px;
  }

  

  .icon-password {
    .MuiButtonBase-root {
      padding: 0px;
    }
  }
}
