@import 'theme/variables', 'theme/colors';

.component-krdescription {
  .description-caption {
    font-size: $font-size-p;
    font-weight: $semibold;
    color: $color-gray;
    text-decoration: underline;
  }
  .description-text {
    color: $color-gray-dark;
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0;
    color: $color-gray;
  }
}
