@import "~theme/variables";

.objectives-actions-bottom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 16px 0px 16px;

  .objectives-actions-bottom-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 6px 0px;
    .MuiListItemIcon-root {
      height: 24px;
      width: 24px;
      min-width: 24px;
      .MuiSvgIcon-root {
        width: inherit;
        height: inherit;
      }
    }

    .MuiListItemText-root {
      margin-left: 16px;
      p{
        font-size: 16px !important;
        font-weight: $medium !important;
      }
    }
  }
}