.component-expandablecell {
  display: flex;
  width: 100%;

  span:not(.expand-icon) {
    display: block;
    flex-grow: 1;
    margin-right: 30px;

    &.collapsed {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .expand-icon {
    height: 19px;
    cursor: pointer;

    svg {
      margin-top: -2px;
    }
  }

  .blank {
    width: 24px;
  }
}
