@import "theme/variables", "theme/colors";

.tegdu-modal {
	.MuiDialog-paper {
		border-radius: 12px;
	}
	.tegdu-modal-content {
		padding: 0px !important;
		.tegdu-modal-container {
			padding: 40px;
			color: $color-black-text;
			position: relative;
			& > button {
				width: 24px;
				background-color: transparent;
				color: $color-black-text;
				border: none;
				font-weight: $bold;
				position: absolute;
				top: 20px;
				right: 35px;
				&:hover {
					opacity: 0.6;
					cursor: pointer;
				}
			}
			.modal-header {
				text-align: center;
				opacity: 0.9;
				margin-bottom: $spacing + 8;
			}
			.message {
				p {
					font-size: $font-size-h4;
					font-weight: $semibold;
				}
			}
			.bottom {
				.footer {
					width: 100%;
					display: flex;
					justify-content: center;
					.MuiButton-root {
						max-width: 100%;
					}
					.modal-button {
						width: 176px;
						background-color: $color-primary;
						font-size: $font-size-p;
						font-weight: $regular;
					}
				}
			}
		}
	}
}
