@import '~theme/variables', '~theme/colors';

.container-new-objective-add-progress-backdrop{
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: absolute;
  bottom: 56px;
  display: flex;
  align-items: flex-end;
  .backdrop{
    height: 100vh;
    background-color: rgba($color-gray-light, 0.3);
    position: absolute;
    width: 100%;
  }
  .container-new-objective-add-progress{
    width: 100%;
    background-color: $color-white;
    border-radius: 20px 20px 0 0;
    position: relative;
    .container-option-to-select{
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;
      padding: 0 20px 0 20px;
      margin: 41px 0 24px 0;
      justify-content: space-between;
    }
  }
}

.hidden-tab{
  display: none ;
}