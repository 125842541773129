@import '~theme/variables', '~theme/colors';

.create-password-form {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  height: calc(100% - 88px);
  .create-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    margin-bottom: 30px;
    .MuiButtonBase-root {
      .MuiButton-label {
        font-size: 14px;
        font-weight: $semibold;
      }
    }
  }
}