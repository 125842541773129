@import 'theme/variables', 'theme/colors';

.member-circle-info{
    margin-top: 48px;
    width: 100%;
    .grid-circle{
        width: 65px;
        margin: auto;
        display: flex;
        .circle{
            margin: auto;    
        }
    }
   
    .grid-info{
        width: 500px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 40px;
        .user{
            text-align: right;
            margin-right: 20px;
            #top{
                font-size: 20px;
                color: $color-black-text;
                font-weight: $bold;
            }
            #bottom{
                font-size: 16px;
                color: $color-gray;
            }
        }
        .line{
            border-left: 1px solid #707070; //TODO: AGREGAR AL THEME
            height: 47px;
        }
        .status{
            margin: auto;
            margin-left: 15px;
        }


        .MuiGrid-grid-md-2 {
            flex-grow: 0;
            max-width: 1%;
            flex-basis: 1%;
        }
        .MuiGrid-grid-md-5 {
            flex-grow: 0;
            max-width: 47%;
            flex-basis: 47%;
        }
    }

}
    
    
/* Reponsive design */
@media only screen and (max-width: 428px){
    .member-circle-info{
        .grid-info{
            display: flex;
            width: 100%;            
            .user{
                display: flex;
                flex-direction: column;                
                min-width: 100%;      
                margin-right: 0px;   
                text-align: center;      
            }
            .line{
                display: none;
            }
            .status{
                display: flex;                
                justify-content: center;
                margin-top: 24px;
                width: 100%;
            }
        }
    }
}