@import 'theme/variables', 'theme/colors', 'theme/fontface';

.search-review-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .search-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    span {
      font-size: $font-size-p;
      margin-left: 15px;
    }
  }
  .review-info-area {
    display: flex;
    width: 100%;
    height: 100%;
    .info-loading {
      min-height: 60vh;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
    .info-error {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .info-success {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 6px;
      
      .info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 14px;
        margin-bottom: 24px;
        & .review-objective-name{
          width: calc(100% / 2.55);
        }
        & .reviewer-name{
          width: 32%;
        }
      }
    }
  }
}
.reviews-search{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 0;
}