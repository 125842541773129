@import '~theme/colors';

.forgot-password-request-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .forgot-password-box {
    padding-right: 20px;
    margin-bottom: 28px;
       #forgot-password {
      font-family: Quicksand, serif;
      font-size: 20px;

    }
  }

  .forgot-password-des {
   padding-right: 20px;
   margin-bottom: 30px;
      #description {
      font-size: 16px;
      font-family: Nunito, serif;

    }
  }

  .forgot-password-form {
    height: inherit;
  }

}

