@import '~theme/variables', '~theme/colors';

.complete-modal {
  background-color: $color-white;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  .modal-content {
    padding: 0 $spacing $spacing;
    .modal-header {
      border-bottom: 2px solid $color-gray-soft;
      height: 64px;
      display: flex;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      /* height: 64px; */
      background-color: $color-white;
      z-index: 10;

      .close-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 108px;
        span{
          cursor: pointer;
        }

        color: $color-gray;
        .icon {
          height: 24px;
          margin: 20px 24px 20px 40px;
        }
        .divider {
          height: 40px;
          width: 2px;
          background-color: $color-gray-soft;
        }
      }
      .title-content {
        padding-left: 24px;
        h3 {
          font-weight: $semibold;
        }
      }
      .actions-content {
        margin-right: 40px;
        margin-left: auto;
        button {
          margin: 0px 5px;
        }
      }
    }
    .modal-body {
      margin: 100px auto 0;
      max-width: 596px;
    }
  }
}

/* Reponsive design */
@media only screen and (max-width: 428px){
  .complete-modal{
    .modal-content{
      .modal-header{
        .close-content{
          width: 20%;
          
          .icon{
            margin-left: 15px;
            margin-right: 15px;
            .MuiIcon-root{
              height: 100%;
              overflow: visible;
            }
          }
        }

        .title-content{
          padding-left: 15px;
          width: 100%;
          h3{
            font-size: $font-size-h4;
          }
        }

        .actions-content{
          display: flex;
          margin-right: 15px;
          justify-content: flex-end;          
          button{
            width: max-content;
          }
        }
      }
    }
  }
}