@import '~theme/variables', '~theme/colors';

.current-password{
    #title{
        color: $color-black-text;
        font-size: $font-size-h4;
        font-weight: $semibold;
        margin-bottom: 12px;
    }
    #content{
        margin-left: 12px;
    }
}