@import 'theme/variables', 'theme/colors';

.tegdu-code-verification-component {
  width: 100%;
  height: 48px;
  .ReactInputVerificationCode__container {
    width: inherit;
    height: inherit;
    .ReactInputVerificationCode__item {
      width: 48px;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .verification-loading {
    display: flex;
    width: 100%;
    height: inherit;
    justify-content: center;
    align-items: center;
  }
}