@import 'theme/colors', 'theme/variables';

.container-teams {
  .container-filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: $spacing;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    .teams-searchbar {
      max-width: 370px;
    }
  }
}

.title{
  display: flex;
  width: 100%;
  .screen-title{
    width: 50%;
  }
  .responsive-button{
    display: flex;
    width: 50%;
    .MuiButton-text{
      margin-left: auto;
    }
  }
}

/* Reponsive design */
@media only screen and (max-width: 429px){
  .container-teams{
    .container-filters{
      display: flex;
      margin-bottom: 15px;
      .MuiButton-text{
        display: none;
      }
      
    }
  }
}

@media only screen and (min-width: 429px){
  .title{
    .responsive-button{
      display: none;
    }
  }
}