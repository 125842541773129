@import '~theme/variables', "~theme/colors";

.containerScoreObjectiveDetailContent{
  .containerHeader{
    padding: 0 0 0 7px;
  }
  .containerInfoContent{
    padding: 16px;
    div{
      margin-top: 15px;
      b{
        color: $color-black-text;
        font-size: 16px;
        font-family: $font-family-primary;
        font-weight: $semibold;
      }
      span{
        color: $color-gray-dark;
        font-size: 16px;
        font-family: $font-family-primary;
        font-weight: $semibold;
      }
      #parentheses{
        color: $color-gray;
        font-size: 12px;
        font-family: $font-family-primary;
        margin-left: 9px;
      }
    }
      .comment{
        font-size: 16px;
        margin-top: 5px;
        font-family: $font-family-primary;
        font-weight: $semibold;
        span{
          color: $color-gray;
        }
    }
  }
  .containerFooterBtn{
    position: fixed;
    width: 100%;
    height: 64px;
    z-index: 100;
    bottom: 0;
    background-color: $color-white;
    display: flex;
    align-items: center;
    padding: 0px 48px 0px 48px;
    box-shadow: none;
    .MuiButton-root{
      width: 100% !important;
      #txt-title{
        margin: 0 0px 0 8px ;
        }
      }
    }
}