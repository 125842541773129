@import "~theme/colors", "~theme/variables";

.objectives-close-action-bottom-container {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 29px 16px;
  width: 100%;
  .objectives-close-action-title {
    font-size: 18px;
    font-weight: $semibold;
    color: $color-black-text;
    text-align: center;
    margin-bottom: 25px;
  }
  .objectives-close-action-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    .objective-instructions {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .objective-icon-text {
      display: flex;
      align-items: center;
      flex-direction: row;      
      width: 100%;
      margin-bottom: 18px;
      .MuiSvgIcon-root {
        color: $color-gray-icons-color;
      }
      span {
        margin-left: 19px;
        color: $color-black-text;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      .cncl-action {
        text-align: center;
        margin-top: 24px;
      }
    }
  }
}