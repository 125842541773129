@import 'theme/colors', 'theme/variables';

.component-circularprogress {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  background-color: $color-gray-f1;

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-family: $font-family-secondary;
    font-weight: $bold;
    font-size: $font-size-p;
    line-height: $font-size-p;
  }
}
