@import "~theme/variables", "~theme/colors";

.container-objectives-KRS-form {
  display: flex;
  flex-direction: column;
  .content-info-kr {
    padding: 20px 16px 29px 16px;
    display: flex;
    .progress-circular {
      margin: 0 16px 0 0;
    }
    .description-target-initial {
      display: flex;
      flex-direction: column;
      .description {
        p {
          color: $color-black-text;
          font-size: 16px;
          b {
            font-weight: $bold;
          }
        }
      }
      .values {
        margin: 18px 0 0 0;
        display: flex;
        gap: 57px;
        color: $color-black-text;
        .text-values {
          span:nth-child(1) {
            font-size: 14px;
          }
          span:nth-child(2) {
            font-size: 14px;
            color: $color-secondary;
            font-weight: $bold;
          }
        }
      }
    }
  }
  .content-info-kr:last-child {
    padding: 29px 0 0 0;
  }
  .container-inputs-add-progress{
    display: flex;
    margin-top: 29px;
    margin-bottom: 40px;
    flex-direction: column;
    .MuiOutlinedInput-input{
      min-height: 48px;
    }
    .container-input-progress-date{
      margin:  0px 16px 0px 16px;
      display: flex;
      flex-direction: column;
      span{
        color: $color-black-text;
        font-size: 14px;
        font-weight: $semibold;
        margin: 0 0 5px 0;
      }
    }
  }
}
.container-all-inputs-keyr{
  width: 100%;
}
.container-objectives-KRS-form:last-child {
  margin-bottom: 100px;
}
