@import 'theme/variables', 'theme/colors';

.objectives-main-actions-header {
  display: flex;
  height: 64px;
  padding: 14px 40px;
  width: 100%;
  background-color: $color-white;
  border-bottom: 1px solid $color-gray-soft;
  .actions-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .basic-info-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 24px;
        border-right: 2px solid rgba($color: #000000, $alpha: 0.12);        
        .MuiSvgIcon-root {
          color: $color-gray;
          cursor: pointer;
        }
      }    
      
      .title {
        font-size: 20px;
        font-weight: $semibold;
        margin-left: 24px;
      }
    }

    .actions-content {
      display: flex;
      align-items: center;
      .is-draft {
        margin-right: 12px;
      }
    }
  }
}