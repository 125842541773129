@import '~theme/variables', '~theme/colors';

.account-container{
    #title{
        color: var(--color-secondary);
    }
    #subtitle{
        margin-top: 24px;
        margin-bottom: 24px;
    }
}