@import '~theme/colors';

.tegdu-mobile-success {
  align-items: center;
  position: relative;
  .icon-tgd-mobile-success{
    display: flex;
    justify-content: center;
    margin: 48px;
    #circleIcon{
      color: $color-primary;
      width: 80px;
      height: 80px;

    }
  }
  .dinamic-text{
    text-align: center;
    font-family: Nunito, serif;
  }
}
