@import '~theme/variables', '~theme/colors';

.invitations-onboarding {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-repeat: repeat;
  background-size: cover;
  padding: $spacing;
}