@import '~theme/variables', '~theme/colors';

.screen-reactivate {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px $spacing;
  background-repeat: repeat;
  background-size: cover;
}
