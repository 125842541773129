@import 'theme/variables', 'theme/colors';

.individual-members{
    margin-top: 43px;
    .back-btn-container{
        width: 120px;
        margin-bottom: 30px; 
        .back-btn{
            font-size: 14px;
            color: $color-gray-dark;
            cursor: pointer;
            .MuiSvgIcon-root{
                margin-right: 12px;
                width: 20px;
                height: 20px;
                color: $color-gray-dark;
            }
        }
    }
    .container-loader{
        max-width: 120px;
        min-height: 500px;
        margin: auto;
        display: flex;
        .loader{
            margin: auto ;
        }
    }
}