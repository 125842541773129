@import 'theme/variables', 'theme/colors';

.members-info-general{
    margin-bottom: 40px;
    margin-left: 24px;
    margin-right: 24px;
    #title{
        align-self: flex-end;
        font-size: $font-size-h4 ;
        font-weight: $bold;
        text-transform: uppercase;
        color: $color-gray-dark;
    }
    #title2{
        font-size: $font-size-h4 ;
        font-weight: $bold;
        text-transform: uppercase;
        color: $color-gray-dark;
        margin-bottom: 5px;
    }
    
    .members-form{
        .form-title{
            flex-wrap: nowrap;
            margin-bottom: 5px;
            .title{
                display: flex;
            }
            .buttons{
                display: flex;
                justify-content: flex-end;
            }
            .buttons-edit{
                display: flex;
                justify-content: flex-end;
                .edit-btn{
                    margin-right: 10px;
                }
            }
        }

        .form-top{
            margin-top: 26px;
            .container-input-role{
                position: relative;
                .container-hr-checkbox{
                    display: flex;
                    flex-direction: row;
                    position: absolute;
                    align-items: center;
                    right: 10px;
                    #hr-check{
                        font-size: 14px;
                        color: $color-black-text;
                    }
                }
            }
        }

        .form-bottom{
            margin-top: 26px;
        }
    }
    hr{
        color: $color-black ;
        opacity: 0.12;
    }
    .members-drop{
        margin-top: 10px;
        height: 200px; // TODO: REVISAR ESTA FORMA DE HACERLO 
    }
    .container-button{
        height: 110px;
        .save-btn{
            margin-top: auto;
        }
    }
    #hr-title{
        margin-bottom:24px ;
    }
}