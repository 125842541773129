@import 'theme/variables', 'theme/colors';

.generic-confirm-modal {
  .MuiDialog-paper {
    width: 488px;
    min-height: 380px;
    border-radius: 12px;
  }
  .confirm-modal-content {
    padding: 0px !important;
    .confirm-modal-container {
      padding: 30px 80px;
      .icon {
        margin-bottom: 25px;
        .div-icon {
          width: 100%;
          display: flex;
          justify-content: center;
          .MuiSvgIcon-root {
            font-size: 80px !important;
            fill: #fdd90a;
          }
        }
      }
      .text-message {
        text-align: center;
        .title {
          justify-content: center;
          font-weight: $regular !important;
        }
        .paragraph {
          margin-top: 20px;
          p {
            font-weight: $regular !important;
            font-size: $font-size-h4 !important;
          }
        }
      }

      .message {
        color: #666666;
        text-align: center;
        .title {
          font-weight: bold;
        }
      }
      .bottom {
        margin-bottom: 0px;
        hr {
          color: $color-black;
          margin-top: 20px;
          opacity: 12%;
        }
        .footer {
          margin-top: 36px;
          width: 100%;
          display: block;
          justify-content: center;
          text-align: center;
          .MuiButton-root {
            max-width: 100%;
          }
          .modal-button {
            width: 345px;
            background-color: var(--color-primary);
            font-size: $font-size-h3;
            font-weight: $medium;
          }
          .secondary {
            font-size: $font-size-h4 !important;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
