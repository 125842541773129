@import 'theme/variables', 'theme/colors';

$transition-time: all 0s ease 0s;

.creatable__select__component{
   min-height: 51px;
}

.creatable__drop__container{
   .creatable__drop__control{ 
      border-color: #aeaeae;
      color: $color-black-text;
      font-size: 14px;
      min-height: 32px;
      transition: $transition-time;
      padding: 1px 0;
   
      &:hover:not(.creatable__drop__control--is-focused) {
         border-color: $color-black;
      }
   
      .creatable__drop__value-container {
         transition: $transition-time;
         padding-top: 0;
         padding-bottom: 0;
   
         .creatable__drop__single-value {
           margin-left: 0;
           width: 100%;
         }
   
         .creatable__drop__multi-value {
           &:first-of-type {
             margin-left: 0;
           }
         }
       }
      .creatable__drop__value-container--has-value {
         padding-top: 0px;
         padding-bottom: 0px;
      }
   
      .creatable__drop__indicators {
         .creatable__drop__indicator-separator {
            background-color: transparent;
         }
         .creatable__drop__dropdown-indicator {
            padding-top: 6px;
            padding-bottom: 6px;
         }
         .creatable__drop__clear-indicator {
            padding-top: 0;
            padding-bottom: 0;
         }
         .MuiSvgIcon-root {
            transition: $transition-time;
            color: $color-gray;
         }
      }
   }

   .creatable__drop__control--is-disabled{
      background-color: transparent ;
   }
   
   &.error .creatable__drop__control {
      border-color: $color-error;
    }
   
    .creatable__drop__control--is-focused {
      border-color: var(--color-primary);
      box-shadow: none; // TODO: REVISAR ESTE PROP
    }
   
    .creatable__drop__menu {
      font-size: 14px;
      .creatable__drop__menu-list {
        .creatable__drop__option{
          text-overflow: ellipsis;
          overflow-x: hidden;
        }
   
        .creatable__drop__option--is-focused {
          background-color: $color-gray-soft;
        }
        .creatable__drop__option--is-selected {
          background-color: $color-gray-soft;
          color: $color-black;
        }
      }
   }
}

.creatable__drop__container.view-mode{
   .creatable__drop__control{
      transition: $transition-time;
      border-color: transparent;
      border-left-width: 0;
      background-color: transparent;
   
      .creatable__drop__value-container {
         transition: $transition-time;
         padding: 0;
      }
   
      .creatable__drop__indicators .MuiSvgIcon-root {
         transition: $transition-time;
         color: transparent;
      }
   
      .creatable__drop__single-value--is-disabled {
         color: $color-black-text;
         margin: 0;
      }
   
      .creatable__drop__multi-value__remove{
         display: none;
      }
      .creatable__drop__multi-value__label{
         padding-right: 6px;
      }
      }
   
}

.creatable__error{
   color: $color-error;
   p{
      font-size: $font-size-s !important;
   }
}