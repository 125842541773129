@import '~theme/variables', '~theme/colors';

.header__container {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  opacity: 1;
  padding: 0 24px;

  .header__nav {
    display: flex;
    align-items: center;
  }

  .company__info {
    display: flex;
    align-items: center;
    .logo__container {
      background-color: $color-gray-soft;
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .company__name {
      margin-left: 12px;
    }
  }
  .vertical__divider {
    background-color: $color-gray-soft-2;
    height: 40px;
    width: 2px;
  }
  .user-info__container {
    width: 260px;
    max-width: 260px;
    .user-info{
      display: flex;
      align-items: center;
      margin-left: 24px;
      .lcircle__container {
        margin-right: 10px;
      }
      h4 {
        text-transform: capitalize;
      }
    }

    .statusDrop__container {
      display: none;
      position: absolute;
      margin-left: 24px;
      .statusDrop {
        position: sticky;
        //left: 20px;
        top: 15px;
        z-index: 1000;
        width: 100%;
        height: 50px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #00000029;
        display: flex;
        align-items: center;
        p {
          text-align: left;
          padding: 2px 18px;
          width: 100%;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          font-size: $font-size-h4;
          &:hover {
            background-color: $color-gray-f1;
          }
        }
      }
    }
    .user-info:hover + .statusDrop__container {
      display: flex;
      width: 100%;
    }
  
    .statusDrop__container:hover {
      display: flex;
      width: 100%;
    }

  }

  .responsive-menu-icon{
    display: flex;
    align-items: center;
    .MuiSvgIcon-root{
      color: $color-gray-icons-color;
      cursor: pointer;
    }
  }
  
}

/* Responsive design */
@media only screen and (min-width: 993px){
  .header__container{
    .responsive-menu-icon{
      display: none;
    }
  }
}

@media only screen and (min-width: 415px) and (max-width: 992px){
  .header__container{
    justify-content: start;
    .header__nav{
      margin-left: auto;
      & .vertical__divider, .user-info__container{
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 414px){
  .sidebar__drawer.open{
    display: none;
  }

  .header__container{
    //flex-direction: row-reverse;
    .header__nav{
      display: none;
    }
  }

  .content.open{
    margin-left: 0px;
    transition: margin-left 0.4s, background-color 0.9s, color 0s;
    transition-timing-function: ease;
  }
}