@import '~theme/variables', '~theme/colors';

.component-warningalert {
  display: flex;
  align-items: center;
  background-color: $color-gray-soft-light;
  border: 2px solid $color-warning;
  border-radius: 6px;
  padding: 14px;

  svg {
    color: $color-warning;
    margin-right: 10px;
  }
  label {
    font-weight: $bold;
    color: $color-gray-dark;
  }
}
