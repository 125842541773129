@import 'theme/variables', 'theme/colors';

.onboardinginvite-container {
  padding: $spacing;
  width: 100%;
  max-width: 780px;
  height: 100%;
  max-height: 600px;

  & > p {
    font-size: $font-size-h4;
    font-weight: $semibold;
    margin-bottom: 34px;
  }

  p.label {
    font-size: $font-size-h4;
    font-weight: $semibold;
    margin-bottom: 4px;
  }

  .buttons {
    margin-top: $spacing;
    display: flex;
    gap: $spacing;
  }
}
