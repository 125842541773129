@import '~theme/colors';

.component-backbutton {
  display: flex;
  justify-content: flex-start;
  .container-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-gray-dark;
    p {
      display: inline-block;
      margin-left: 12px;
    }
  }
}
