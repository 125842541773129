@import '~theme/variables', '~theme/colors';

.forgot-password-code-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
  .code-area {
    span {
      font-size: 16px;
      color: $color-black-text;
      font-weight: $semibold;
    }
    .tegdu-code-verification-component{
      margin-top: 8px;
    }
    margin-top: 30px;
  }

  .button-area {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    .MuiButtonBase-root {
      .MuiButton-label {
        font-size: 14px;
        font-weight: $semibold;
      }
    }
  }
}