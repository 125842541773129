@import 'theme/variables';
.objectives-key-results-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  .key-results-input {
    grid-row-gap: 0px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    .key-section {
      margin-bottom: 16px;
      .instructions-title {
        font-size: 20px;
        font-weight: $semibold;
      }
      .instructions-description {
        font-size: 14px;
      }
    }
    .metrics {
      display: flex;
      flex-direction: column;
      width: 100%;
      .metrics-instructions {
        .instructions-title {
          font-size: 16px;
          font-weight: $semibold;
        }
        .instructions-description {
          font-size: 14px;
        }
      }
      .metric-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        span {
          font-size: 14px;
        }
        .selector {
          margin-left: 8px;
          width: 180px !important;
        }
      }
      .metric-values {
        display: flex;
        gap: 20px;
        .values-input {
          width: 175px;
        }
      }
      .extra-messages {
        min-height: 16px;
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .add-kr-button{
        width: 50%;
      }
      .add-key-result {
      }
    }
  }
}