@import '~theme/variables', '~theme/colors';

.lcircle__container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  font-weight: $bold;
  background-color: $color-gray;
  border-radius: 50%;
  font-family: $font-family-secondary;
  cursor: default;

  &.xs {
    width: 24px;
    min-width: 24px;
    height: 24px;
    font-size: $font-size-xs;
  }

  &.sm {
    width: 28px;
    min-width: 28px;
    height: 28px;
    font-size: $font-size-label;
  }

  &.md {
    width: 36px;
    min-width: 36px;
    height: 36px;
    font-size: $font-size-tag;
  }

  &.lg {
    width: 64px;
    min-width: 64px;
    height: 64px;
    font-size: 29px;
  }

  &.xl {
    width: 80px;
    min-width: 80px;
    height: 80px;
    font-size: 40px;
  }
}
