@import '~theme/variables', '~theme/colors';

.card-table-general-objectives{
  position: relative;
  .container-see-archived{
    position: absolute;
    bottom: 10px;
    left: 5px;
    .btn-see-archived:hover{
      background-color: $color-gray-f7;
      color: $color-gray-ae;
    }
  }
}