@import '~theme/variables', '~theme/colors';


  .container-buttons-filters-reviews{
    height: 40px;
    display: flex;
    margin-bottom: 30px;
    column-gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    .button-filter-selected{
      padding: 10px !important;
      height: 32px;
      color: $color-white;
      background-color: $color-gray-dark;
      border-radius: 16px !important;
      font-weight: normal !important;
      font-size: 14px;
      font-family: $font-family-primary !important;
      .MuiButton-label{
        font-weight: normal !important;
      }
    }
    .button-filter{
      padding: 10px !important;
      height: 32px;
      color: $color-black-text;
      background-color: $color-gray-f1;
      border-radius: 16px !important;
      font-size: 14px;
      font-family: $font-family-primary !important;
      .MuiButton-label{
        font-weight: normal !important;
      }
    }
  }  
