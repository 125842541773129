@import '~theme/variables', '~theme/colors';

.component-item-individualrate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing;
  width: 400px;

  .item-individualrate-user {
    display: flex;
    gap: 16px;

    .icon {
      font-size: 20px;
      color: $color-gray-icons-color;
    }
    .user-container {
      .user {
        font-size: $font-size-h4;
        font-weight: $medium;
        color: $color-black-text;
      }
      .role {
        color: $color-gray;
      }
    }
  }
}