@import 'theme/variables';

.create-objectives-form {
  display: flex;
  flex-direction: column;
  
  width: 100%;  
  position: absolute;

  .create-objectives-form-header {
    position: fixed;
    width: 100%;
    z-index: 100;
  }

  .create-objectives-main-form-content {
    width: 100%;
    margin-top: 84px;
    margin-bottom: 16px;
    padding: 0px 16px;
    overflow-x: hidden;
    height: auto;
    .instructions-section {
      margin-bottom: 25px;
      .instructions-title {
        font-size: 20px;
        font-weight: $semibold;
      }
    }
    
    .create-objectives-main-form-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .instructions {
        .instructions-title {
          font-size: 20px;
          font-weight: $semibold;
        }
    
        .instructions-description {
          font-size: 14px;
        }
      }
    }
  }

}

