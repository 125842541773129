@import 'theme/variables', 'theme/colors';

.tegdu-tag-component {
  display: flex;
  &.timeframe-options {
    border-radius: 4px;
    background-color: $color-gray-soft-2;
    padding: 3px 11px 2px 11px;
    font-size: 14px;
  }
}