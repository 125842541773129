@import 'theme/variables', 'theme/colors';

button.objective-type-button {
  padding: $spacing !important;
  border-radius: 25px !important;
  margin-right: 20px;
  &.selected {
    background: var(--color-primary) !important;
    color:$color-white !important;
  }
}

button.culturalAttributes-type-button {
  height: 32px;
  border-radius: 25px !important;
  margin-right: 20px;
  font-size: 14px;
  .MuiButton-label {
    font-weight: $regular;
  }
  &.selected {
    background: var(--color-primary) !important;
    color:$color-white !important;
  }
}
