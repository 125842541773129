@import "~theme/variables", "~theme/colors";

.generic-data-table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  //padding: 0;
  margin-top: 5px;
  .container-progress {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
    margin-top: 10px;
  }
}

.name-okr-mobile {
  overflow: hidden;
  height: 20px;
  width: 140px;
  display: block;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
