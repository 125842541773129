@import "~theme/variables", "~theme/colors";

.mark-as-closed-modal{
  display: flex;
  flex-direction: column;
  padding: 26px 16px 28px;
  
  & .header{
    display: flex;
    column-gap: 16px;
    align-items: center;

    & span{
      font-size: $font-size-tag;
      font-weight: $semibold;
      color: $color-black-text;
    }
  }

  & .close-btn{
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: transparent;
  }
}