@import '~theme/variables', '~theme/colors';

.component-labelwithcheckbox {
  display: flex;
  align-items: center;
  gap: 9px;

  .MuiCheckbox-root {
    padding: 0;

    .MuiSvgIcon-root {
      padding: 0;
      font-size: 20px;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: var(--color-primary) !important;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: var(--color-primary) !important;
  }

  .thin {
    font-size: $font-size-p;
    font-weight: $medium;
  }
}
